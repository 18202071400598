/* @flow */

import { decorate, observable } from 'mobx'
import BaseRepository, { defaults } from './BaseRepository'
import ProductServices, {
  ProductAttributeValuesServices,
  ProductCustomOptionServices,
  ProductImageServices,
  ProductOptionServices,
} from 'services/product'

class ProductRepository extends BaseRepository {
  service: ProductServices
  ProductAttributes = {
    loading: false,
    combinationAttributes: [],
  }
  ProductsByCategory = {
    data: [],
    loading: false,
  }
  StoreProducts = {
    data: [],
    loading: false,
  }
  TableListPrice = defaults.Table
  BulkAction = defaults.CRUD
  Export = defaults.CRUD
  CustomOptions = {
    data: [],
    loading: false,
    errors: [],
  }
  FlexibleCustomOptionsTable = {
    originalData: {},
    data: [],
    loading: false,
    totalRecordCount: 0,
    errors: [],
  }
  ProductClone = {
    data: [],
    loading: false,
    errors: [],
  }

  constructor(service: ProductServices) {
    super(service)
    this.service = service
  }
  bulkPost = async (key, payload, callback) => {
    this.BulkAction.errors = []
    this.BulkAction.loading = true
    const { data, errors } = await this.service.bulkPost(key, payload)
    if (data && !errors.length) {
      this.BulkAction.loadingFailed = false
      if (callback) callback(data)
    } else {
      this.BulkAction.loadingFailed = true
      this.BulkAction.errors = errors
    }
    this.BulkAction.loading = false
  }
  fetchListPrice = async params => {
    this.TableListPrice.loading = true
    const { data, errors } = await this.service.fetchListPrice(params)
    if (data && !errors.length) {
      this.TableListPrice.originalData = data
      this.TableListPrice.data = data.content
      this.TableListPrice.totalRecordCount = data.totalElements
    }
    this.TableListPrice.loading = false
  }
  fetchFlexibleCustomOptions = async params => {
    this.FlexibleCustomOptionsTable.loading = true
    const { data, errors } = await this.service.fetchTable(params)
    if (data && !errors.length) {
      this.FlexibleCustomOptionsTable = {
        ...this.FlexibleCustomOptionsTable,
        originalData: data,
        data: data.content,
        totalRecordCount: data.totalElements,
        loading: false,
        errors: [],
      }
      this.FlexibleCustomOptionsTable.loading = false
    } else {
      this.FlexibleCustomOptionsTable.errors = errors
      this.FlexibleCustomOptionsTable.loading = false
    }
  }
  clearFlexibleCustomOptionsData = () => {
    this.FlexibleCustomOptionsTable = {
      originalData: {},
      data: [],
      loading: false,
      totalRecordCount: 0,
      errors: [],
    }
  }
  export = async (payload, callback) => {
    this.Export.errors = []
    this.Export.loading = true

    const exportResult = await this.service.export('export', payload)
    if (exportResult.data && !exportResult.errors.length) {
      // Get final result from SSE and convert it to JSON
      try {
        const finalResultString = exportResult.data
          .trim()
          .split('\n')
          .filter(item => !!item)
          .pop()
          .replace('data:', '')
        const jsonResult = JSON.parse(finalResultString)

        if (jsonResult.completeInd) {
          // Download generated file
          const downloadResult = await this.service.downloadExportedFile(
            { fileName: jsonResult.fileName },
            { responseType: 'blob' }
          )
          if (downloadResult.data && !downloadResult.errors.length) {
            callback(downloadResult.data)
          } else {
            this.Export.errors = downloadResult.errors
          }
        }
      } catch (error) {
        this.Export.errors = [{ message: error.message }]
      }
    } else {
      this.Export.errors = exportResult.errors
    }
    this.Export.loading = false
  }
  getStoreProducts = async id => {
    this.StoreProducts.loading = true
    const { data, errors } = await this.service.getStoreProducts(id)
    this.StoreProducts.loading = false
    if (data && !errors.length) {
      this.StoreProducts.data = data
    }
  }
  getProductAttributesPricingRules = async () => {
    this.ProductAttributes.loading = true
    const { data, errors } = await this.service.getProductAttributesPricingRules()
    this.ProductAttributes.loading = false
    if (data && !errors.length) {
      this.ProductAttributes.combinationAttributes = data
    }
  }
  getProductsByCategory = async (params, categoryId) => {
    this.ProductsByCategory.loading = true
    const { data, errors } = await this.service.getProductsByCategory(params, categoryId)
    this.ProductsByCategory.loading = false
    if (data && !errors.length) {
      this.ProductsByCategory.data = data.content
      this.ProductsByCategory.totalRecordCount = data.totalElements
      this.ProductsByCategory.totalPages = data.totalPages
    }
  }
  getSelectAttribute = async (params, productId) => {
    const { data = [], errors } = await this.service.getSelectAttribute(params, productId)
    return { data: data && data.map(d => ({ attributeLabel: d, attributeValue: d })), errors }
  }
  getSmartAddCustomOptions = async payload => {
    this.CustomOptions = {
      ...this.CustomOptions,
      loading: true,
      errors: [],
    }
    this.CRUD.errors = []
    const { data, errors } = await this.service.getSmartAddCustomOptions(payload)
    if (data && !errors.length) {
      this.CustomOptions = {
        ...this.CustomOptions,
        data,
        loading: false,
      }
    } else {
      this.CustomOptions = {
        ...this.CustomOptions,
        loading: false,
        errors,
      }
    }
  }
  convertToVirtualNoSN = async payload => {
    this.CRUD.loading = true
    const { data, errors } = await this.service.convertToVirtualNoSN(payload)
    if (errors.length) {
      this.CRUD.errors = errors
    }
    this.CRUD.loading = false
    return { data, errors }
  }
  cloneProduct = async (productId, payload) => {
    this.ProductClone = {
      ...this.ProductClone,
      loading: true,
      errors: [],
    }
    this.CRUD.errors = []
    const { data, errors } = await this.service.cloneProduct(productId, payload)
    if (data && !errors.length) {
      this.ProductClone = {
        ...this.ProductClone,
        data,
        loading: false,
      }
    } else {
      this.ProductClone = {
        ...this.ProductClone,
        loading: false,
        errors,
      }
    }
  }
}

export class ProductImageRepository extends ProductRepository {
  constructor(service: ProductImageServices) {
    super(service)
    this.service = service
  }

  imageUrl = (productId, id) => {
    return `${process.env.REACT_APP_API_SERVER}${this.service.url}/${productId}/images/${id}`
  }

  setDefaultImage = async (productId, id) => {
    const { data, errors } = await this.service.setDefaultImage(productId, id)
    return { data, errors }
  }
}

export class ProductOptionRepository extends ProductRepository {
  constructor(service: ProductOptionServices) {
    super(service)
    this.service = service
  }
}

export class ProductCustomOptionRepository extends ProductRepository {
  constructor(service: ProductCustomOptionServices) {
    super(service)
    this.service = service
  }
}

export class ProductAttributeRepository extends ProductRepository {
  constructor(service: ProductAttributeServices) {
    super(service)
    this.service = service
  }

  getValues = () => this.service.getValues()

  getProductByProductCode = payload => {
    return this.service.getProductByProductCode({ productCode: payload.search })
  }
}

export class ProductAttributeValuesRepository extends ProductRepository {
  constructor(service: ProductAttributeValuesServices) {
    super(service)
    this.service = service
  }
}

export default decorate(ProductRepository, {
  BulkAction: observable,
  CustomOptions: observable,
  Export: observable,
  FlexibleCustomOptionsTable: observable,
  ProductAttributes: observable,
  ProductClone: observable,
  ProductsByCategory: observable,
  StoreProducts: observable,
  TableListPrice: observable,
})
