import { observer } from 'mobx-react'
import downloadjs from 'downloadjs'
import React, { Component } from 'react'

import { formatDate } from 'helpers/Formatters'
import { sort } from 'helpers/Array'
import Checkbox from 'components/Common/Form/Checkbox'
import Form from 'components/Common/Form'
import FormContainer from 'components/Common/Form/Container'
import Modal from 'components/Common/Modal'
import RadioGroup from 'components/Common/Form/RadioGroup'
import ReactSelect from 'components/Common/Form/ReactSelect'

const ALL = 'all'
const FILTERED = 'filtered'
const GENERAL = 'general'
const LIST_PRICE = 'list-price'
const STORE = 'store'

class ExportModal extends Component {
  state = {
    errors: [],
  }
  constructor(props) {
    super(props)

    const fields = {
      export: {
        label: 'Extract',
        value: FILTERED,
      },
      view: {
        label: 'List',
        value: GENERAL,
      },
      includeAttribute: {
        label: 'Include Attribute',
        type: 'checkbox',
        value: false,
      },
      stores: {
        label: 'Stores',
        type: 'array',
        value: [],
        validators: [this.validateStores],
      },
    }

    this.stores = sort(props.stores, 'name')

    this.form = new Form({ fields }, { name: 'ExportModalForm', handleSubmit: this.handleSubmit })
    this.form.$('stores').map(storeFied =>
      storeFied.add({
        key: 'selected',
        type: 'checkbox',
        label: storeFied.$('name').value,
        value: true,
      })
    )

    props.productRepository.Export.errors = []
  }

  validateStores = ({ form, field }) => {
    const isStore = form.$('view').value === STORE
    if (isStore) {
      if (field.value?.length) {
        if (field.value.length > 5) {
          return [false, 'Only up to 5 stores is allowed']
        }
      } else {
        return [false, 'At least 1 store is required']
      }
    }
    return [true]
  }

  handleSubmit = () => {
    const { productRepository, filters: filtersProp, onClose } = this.props
    const { export: exportInd, stores, view, includeAttribute } = this.form.values()

    // Transform filters for payload
    let filters = {}
    if (exportInd !== ALL) {
      const { productAttributes, productCategories, productSeries, productNature, orderProcess, ...rest } = filtersProp
      filters = { ...rest }

      if (productAttributes) {
        filters.productAttributes = productAttributes.map(item => item.value)
      }
      if (productCategories) {
        filters.productCategories = productCategories.map(item => item.value)?.join(',') || ''
      }
      if (productSeries) {
        filters.productSeries = productSeries.value
      }
      if (orderProcess) {
        filters.orderProcess = orderProcess.value
      }
      if (productNature?.length) {
        const [item] = productNature
        if (item.value !== 'ALL') {
          filters.productNatures = productNature.map(item => item.value)
        }
      }
    }

    const { productNatures, ...rest } = filters
    const payload = {
      ...(exportInd === ALL
        ? {}
        : {
            ...rest,
            productNatures: productNatures || ['all'],
          }),
      export: exportInd,
      exportGeneral: view === GENERAL,
      exportListPrice: view === LIST_PRICE,
      exportCustomerGroup: view === STORE,
      storeIds: stores.map(item => item.value),
      includeAttribute,
    }

    productRepository.export(payload, result => {
      const dateStamp = formatDate(new Date(), 'YYYYMMDDHHmm')
      downloadjs(
        result,
        `SA products_${dateStamp}.xlsx`,
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      )
      onClose()
    })
  }

  selectAll = select => {
    this.form.$('general').set(select)
    this.form.$('price').set(select)
    this.form.$('stores').map(storeFied => storeFied.$('selected').set(select))
  }

  render() {
    const {
      productRepository: {
        Table: { totalRecordCount },
        Export: { loading, errors },
      },
      open,
      onClose,
    } = this.props
    const selectedView = this.form.$('view').value
    const exceededFilterLimit = this.form.$('export').value === FILTERED && totalRecordCount > 2000

    return (
      <Modal
        title="Export Options"
        size={this.stores.length > 15 ? 'md' : 'sm'}
        open={open}
        onConfirm={() => this.buttonSubmit.click()}
        onClose={() => !loading && onClose()}
        loading={loading}
        disabled={exceededFilterLimit}
      >
        {exceededFilterLimit && (
          <div className="alert alert-primary font-size-14 mb-0" role="alert">
            Please apply filter(s). Filter result should be less than 2,000
          </div>
        )}
        <FormContainer
          onSubmit={event => this.form.onSubmit(event, { onSuccess: this.handleSubmit })}
          plain
          errors={errors}
        >
          <RadioGroup
            field={this.form.$('export')}
            radios={[
              {
                label: 'Filtered results',
                value: FILTERED,
              },
              {
                label: 'All products',
                value: ALL,
              },
            ]}
            disabled={loading}
            radioListClassName="ml-3"
          />
          <RadioGroup
            field={this.form.$('view')}
            radios={[
              {
                label: 'General View',
                value: GENERAL,
                children: (
                  <Checkbox
                    field={this.form.$('includeAttribute')}
                    disabled={loading || selectedView !== GENERAL}
                    className="m-0 ml-4 mb-1"
                    inputClassName="m-0"
                  />
                ),
              },
              {
                label: 'Product price per store',
                value: LIST_PRICE,
                className: 'mb-1',
              },
              {
                label: 'Product price per customer group',
                value: STORE,

                children: (
                  <ReactSelect
                    field={this.form.$('stores')}
                    customLabelKey={['label', 'value']}
                    options={{
                      isDisabled: loading || selectedView !== STORE,
                      isMulti: true,
                      closeMenuOnSelect: this.form.$('stores').value?.length >= 4,
                      options: this.stores.map(item => ({
                        label: item.name,
                        value: item.id,
                      })),
                    }}
                    className="my-0 ml-4"
                  />
                ),
              },
            ]}
            radioListClassName="ml-3 mb-4"
          />
          <button
            ref={ref => (this.buttonSubmit = ref)}
            type="submit"
            className="collapse"
            disabled={loading || exceededFilterLimit}
          />
        </FormContainer>
      </Modal>
    )
  }
}

export default observer(ExportModal)
