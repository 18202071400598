import { Badge, Button, Col, CustomInput, Row } from 'reactstrap'
import { decorate } from 'mobx'
import { head } from 'lodash'
import { inject, observer } from 'mobx-react'
import { Link } from 'react-router-dom'
import cx from 'classnames'
import React from 'react'
import styled from 'styled-components'

import { PRODUCT_NATURE_PHYSICAL, PRODUCT_NATURE_VIRTUAL, PRODUCTS_PATH } from 'definitions'
import ContentContainer from 'components/Common/ContentContainer'
import ReactTable from 'components/Common/ReactTable'

const pageKey = 'product-options'

const StatusButton = ({ status, className, selected, handleFilterStatus, ...rest }) => {
  return (
    <Button
      size="xs"
      style={{ width: '80px' }}
      className={cx(className, selected && 'selected')}
      onClick={() => handleFilterStatus(status?.value)}
      {...rest}
    >
      {status?.label || 'All'}
    </Button>
  )
}

class ProductOptions extends React.Component {
  table = React.createRef()
  filters = {}
  state = {
    expanded: {},
  }

  constructor(props) {
    super(props)
    this.initializeDefaultFilters()
  }
  initializeDefaultFilters = () => {
    const { searchRepository } = this.props
    const defaultFilters = searchRepository.Pages[pageKey]?.filters
    if (defaultFilters) {
      this.filters = defaultFilters
    }
  }

  handleFilterStatus = status => {
    if (status) {
      this.filters.productNature = status
    } else {
      this.filters = {}
    }
    this.props.searchRepository.updateSearchPage(pageKey, { filters: this.filters })
    this.table && this.table.reload()
  }
  handleCheckedChange = e => {
    this.filters.showUnlinked = e.target.checked
    this.props.searchRepository.updateSearchPage(pageKey, { filters: this.filters })
    this.table && this.table.reload()
  }

  getData = params => {
    const {
      productOptionRepository: { fetchTable },
    } = this.props
    fetchTable({ ...params, ...this.filters }).then(() => {
      const data = this.props.productOptionRepository.Table.data
      if (data.length) {
        const defaultExpandedRows = {}
        data.forEach((_, index) => {
          defaultExpandedRows[index] = true
        })
        this.setState({ expanded: defaultExpandedRows })
      }
    })
  }

  render() {
    const {
      productOptionRepository: {
        Table: { data, loading, originalData, totalRecordCount },
      },
      searchRepository,
    } = this.props

    let newData = []
    data.forEach(row => {
      const items = [...row.associatedProducts]
      if (row.associatedProducts.length > 0) {
        row.products = head(items)
      }
    })
    return (
      <ContentContainer title="Product Options">
        <Row className="mb-3">
          <Col>
            <StatusButton
              color="dark"
              selected={!this.filters?.productNature}
              handleFilterStatus={this.handleFilterStatus}
            />
            <StatusButton
              status={{
                label: 'Physical',
                value: PRODUCT_NATURE_PHYSICAL,
              }}
              selected={this.filters?.productNature == PRODUCT_NATURE_PHYSICAL}
              color="cyan"
              className="mx-2"
              handleFilterStatus={this.handleFilterStatus}
            />
            <StatusButton
              status={{
                label: 'Virtual',
                value: PRODUCT_NATURE_VIRTUAL,
              }}
              selected={this.filters?.productNature == PRODUCT_NATURE_VIRTUAL}
              color="primary"
              handleFilterStatus={this.handleFilterStatus}
            />
          </Col>
          <Col className="d-flex justify-content-end">
            <CustomInput
              id="showUnlinked"
              type="switch"
              label="Show Unlinked"
              defaultChecked={this.filters?.showUnlinked}
              onChange={this.handleCheckedChange}
            />
          </Col>
        </Row>
        <TableStyled
          searchRepository={searchRepository}
          pageKey={pageKey}
          getRowUrl={({ original }) => `${PRODUCTS_PATH}/${original.productId}`}
          enableRowLink
          columns={[
            {
              width: 20,
              sortable: false,
              getProps: (_, { original = {} } = {}) => ({
                className: cx('', {
                  'border-primary': original.productNature?.toLowerCase() === PRODUCT_NATURE_PHYSICAL,
                  'border-cyan': original.productNature?.toLowerCase() === PRODUCT_NATURE_VIRTUAL,
                }),
                style: {
                  padding: 0,
                  ...(original.productNature && { borderLeft: '5px solid' }),
                },
              }),
            },
            {
              accessor: 'productCode',
              Header: 'Product Code',
              width: 260,
            },
            {
              accessor: 'name',
              Header: 'Parent Products',
              width: 280,
            },
            {
              expander: true,
              width: 20,
              sortable: false,
              className: 'justify-content-center stop-redirect',
              Expander: ({ isExpanded, ...rest }) =>
                rest.original.associatedProducts.length > 1 ? (
                  <div>
                    {isExpanded ? (
                      <i className="icon ion-md-arrow-dropdown" />
                    ) : (
                      <i className="icon ion-md-arrow-dropright" />
                    )}
                  </div>
                ) : (
                  ''
                ),
            },
            {
              Header: 'Associated Products',
              sortable: false,
              minWidth: 400,
              className: 'stop-redirect',
              Cell: ({ original }) =>
                !!original.products && (
                  <Link to={`${PRODUCTS_PATH}/${original.products.productId}`} className="ellipsis d-flex">
                    <Badge title={original.products.productCode} className="ellipsis col-auto">
                      {original.products.productCode}
                    </Badge>{' '}
                    <span title={original.products.name} className="ellipsis">
                      {original.products.name}
                    </span>
                  </Link>
                ),
            },
          ]}
          className="striped cell-vertical-center mb-3 horizontal-scroll"
          sort={{ id: 'productCode' }}
          pageSize={newData.length}
          perPageOptions={[10, 20, 30, 40, 50]}
          defaultPageSize={10}
          data={data}
          loadData={this.getData}
          loading={loading}
          totalRecordCount={totalRecordCount}
          totalPages={originalData.totalPages}
          ref={ref => (this.table = ref)}
          search
          expanded={this.state.expanded}
          SubComponent={row => {
            const newItems = [...row.original.associatedProducts]
            newItems.splice(0, 1)
            const items = newItems
            return (
              <React.Fragment>
                {!!items.length && (
                  <ReactTable
                    className="m-0"
                    TheadComponent={() => null}
                    data={items.reverse()}
                    pageSize={items.length}
                    showRowPerPage={false}
                    showPager={false}
                    columns={[
                      {
                        width: 20,
                      },
                      {
                        width: 260,
                      },
                      {
                        width: 280,
                      },
                      {
                        width: 20,
                      },
                      {
                        Header: 'Product Name',
                        accessor: 'name',
                        minWidth: 400,
                        Cell: ({ original }) => (
                          <React.Fragment>
                            <Link to={`${PRODUCTS_PATH}/${original.productId}`} className="ellipsis d-flex">
                              <Badge title={original.productCode} className="ellipsis col-auto">
                                {original.productCode}
                              </Badge>{' '}
                              <span title={original.name} className="ellipsis">
                                {original.name}
                              </span>
                            </Link>
                          </React.Fragment>
                        ),
                      },
                    ]}
                  />
                )}
              </React.Fragment>
            )
          }}
          onExpandedChange={(expanded, index, event) => {
            this.setState({ expanded })
          }}
        />
      </ContentContainer>
    )
  }
}
export default decorate(inject('productOptionRepository', 'searchRepository')(observer(ProductOptions)), {})

const TableStyled = styled(ReactTable)`
  &.ReactTable {
    margin-bottom: 0;
  }
  .badge {
    font-size: 11px;
    font-weight: normal;
    color: #555;
    background-color: ${props => props.theme.gray300};
    margin-right: 1rem;
  }
`
