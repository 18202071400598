import React, { Component } from 'react'

import { computed, decorate } from 'mobx'
import { inject, observer } from 'mobx-react'

import { Button, Nav, Navbar, NavbarBrand, NavItem } from 'reactstrap'
import styled from 'styled-components'

import { ENV_PRODUCTION } from 'definitions'
import { Visible } from 'helpers/Auth'
import AppsDropdown from 'components/Common/AppsDropdown'
import ContextMenu from 'components/Common/ContextMenu'
import GlobalSearch from 'components/GlobalSearch'

import appsDropdownMenuSvg from 'images/apps_dropdown_menu.svg'
import backendSvg from 'images/add database.svg'
import createOrderSvg from 'images/add to bag.svg'
import createProductSvg from 'images/add product.svg'
import createUserSvg from 'images/add user.svg'
import logo from 'images/logo.svg'
import SidebarMenu from './SidebarMenu'

class Header extends Component {
  handleChange = event => {
    this.setState({ auth: event.target.checked })
  }
  handleItemClick = (e, name) => {
    this.props.history.push(name)
    this.setState({ activeItem: name })
  }
  toggleSideBar = () => {}
  handleLogout = () => {
    this.props.history.push('/logout')
  }
  handleCreateOrder = () => {
    this.props.history.push('/orders/new')
  }
  handleCreateProduct = () => {
    this.props.history.push('/products/new')
  }
  handleCreateCustomer = () => {
    this.props.customerRepository.clearCrudData()
    this.props.history.push('/customers/new')
  }
  handleViewProfile = () => {
    const userId = this.props.authRepository.getUserId()
    this.props.history.push(`/profile/${userId}`, { userId })
  }
  render() {
    const { authRepository } = this.props

    const user = authRepository.getUser()

    let initials
    if (user.firstName) {
      initials = user.firstName.charAt(0)
      if (user.lastName) {
        initials = `${initials}${user.lastName.charAt(0)}`
      }
    }

    return (
      <React.Fragment>
        <HeaderStyled fixed="top" className="bg-white">
          <NavbarBrand href="/">
            <img src={logo} alt="Peplink" />
          </NavbarBrand>

          <div className="nav-menu">
            <GlobalSearch />
            <Nav className="ml-auto" navbar>
              <Visible toAppAdminRole>
                <NavIconStyled>
                  <a
                    href="https://backend.peplink.com/"
                    target="_blank"
                    className="btn btn-transparent"
                    rel="noopener noreferrer"
                  >
                    <img src={backendSvg} alt="Go to Peplink Backend" title="Go to Peplink Backend" />
                  </a>
                </NavIconStyled>
                <NavIconStyled>
                  <Button color="transparent" onClick={this.handleCreateCustomer}>
                    <img src={createUserSvg} alt="Create Customer" title="Create Customer" />
                  </Button>
                </NavIconStyled>
                <NavIconStyled>
                  <Button color="transparent" onClick={this.handleCreateProduct}>
                    <img src={createProductSvg} alt="Create Product" title="Create Product" />
                  </Button>
                </NavIconStyled>
              </Visible>
              <Visible toOrderProcessRole>
                <NavIconStyled>
                  <Button color="transparent" onClick={this.handleCreateOrder}>
                    <img src={createOrderSvg} alt="Create Order" title="Create Order" />
                  </Button>
                </NavIconStyled>
              </Visible>
              {process.env.REACT_APP_ENV !== ENV_PRODUCTION && (
                <NavIconStyled>
                  <AppsDropdown
                    render={toggle => (
                      <Button color="transparent" onClick={toggle}>
                        <img src={appsDropdownMenuSvg} alt="Peplink Apps" title="Peplink Apps" />
                      </Button>
                    )}
                  />
                </NavIconStyled>
              )}
              <ContextMenu
                plain
                dropDownToggle={
                  <React.Fragment>
                    <span className="avatar">{initials}</span>
                    <h6 className="d-inline-flex username">{user.fullName || ''}</h6>
                  </React.Fragment>
                }
                menus={[
                  {
                    text: 'Profile',
                    onClick: this.handleViewProfile,
                  },
                  {
                    text: 'Logout',
                    onClick: this.handleLogout,
                  },
                ]}
              />
            </Nav>
          </div>
        </HeaderStyled>
        <SidebarMenu />
      </React.Fragment>
    )
  }
}

const NavIconStyled = styled(NavItem)`
  .btn {
    line-height: 0;
    border-radius: 50%;
    padding: 0.5rem;
    &.btn-transparent:hover {
      background-color: #e7e7e7;
    }
  }
`

const HeaderStyled = styled(Navbar)`
  &.navbar {
    border-bottom: 1px solid #e0e0e0;
    height: 50px;
    padding: 0 0.95rem;
    .navbar-brand {
      padding: 0;
      img {
        width: 10rem;
      }
    }
    .nav-menu {
      display: flex !important;
      flex-basis: auto;
      flex-grow: 1;
      .navbar-nav {
        flex-direction: row;
        align-items: center;
      }
    }
    .avatar {
      display: inline-flex;
      justify-content: center;
      width: 35px;
      height: 35px;
      position: relative;
      overflow: hidden;
      font-size: 0.8rem;
      align-items: center;
      flex-shrink: 0;
      user-select: none;
      border-radius: 50%;
      color: #fff;
      background-color: #bdbdbd;
      margin: 0 8px;
    }
    .username {
      font-size: 13px;
      font-weight: normal;
      color: #333333;
    }
    .username:hover {
      color: #ffb81c;
    }
  }
`

export default decorate(inject('authRepository', 'loginRepository', 'customerRepository')(observer(Header)), {
  currentUser: computed,
})
