/* eslint-disable consistent-return */
import React, { Component } from 'react'

import { Col, Row } from 'reactstrap'
import { decorate } from 'mobx'
import { inject, observer } from 'mobx-react'

import { allowedToOrderRole } from 'helpers/Auth'
import { fields } from './fieldset'
import Checkbox from 'components/Common/Form/Checkbox'
import CommonSelect from 'components/Common/CommonSelect'
import Form from 'components/Common/Form/index'
import FormContainer from 'components/Common/Form/Container'
import Input from 'components/Common/Form/Input'
import Modal from 'components/Common/Modal'
import Prompt, { DEFAULT_CONFIRM_MESSAGE } from 'components/Common/Prompt'
import ToasterNotify from 'helpers/ToasterNotify'

class AddressFormModal extends Component {
  form = null
  constructor(props) {
    super(props)
    this.state = {
      hasAddressTypeError: false,
    }
    if (props.form) {
      this.form = props.form
    } else {
      this.form = new Form(
        { fields: fields(props.data, props.customer) },
        {
          name: 'AddressForm',
          hooks: {
            onSuccess: this.handleSubmit,
            onError: this.validateAddressType,
          },
        }
      )
    }
  }

  componentWillMount() {
    this.props.commonRepository.getCommon('countries', 'countries', {})
  }
  componentWillUnmount() {
    this.props.customerAddressRepository.clearErrorMessages()
    // this.props.setSelected({})
  }

  handleSubmit = form => {
    const values = form.values()
    const { data, editMode, customer } = this.props

    const { billingOnly = false, shippingOnly = false } = values

    if (!billingOnly && !shippingOnly) {
      this.setState({ hasAddressTypeError: true })
      return false
    }

    if (data && data.id && editMode) {
      this.props.customerAddressRepository.patch(
        { ...values, id: data.id },
        () => {
          this.props.table.reload()
          this.props.toggleModal()
          this.props.onSubmit()
        },
        customer.id
      )
    } else {
      this.props.customerAddressRepository.create(
        values,
        () => {
          this.props.table.reload()
          this.props.toggleModal()
          this.props.onSubmit()
        },
        customer.id
      )
    }
  }
  triggerSubmit = e => {
    document.getElementById('ca-submit').click()
  }
  validateAddressType = () => {
    const { billingOnly = false, shippingOnly = false } = this.form.values()
    if (!billingOnly && !shippingOnly) {
      this.setState({ hasAddressTypeError: true })
    } else {
      this.setState({ hasAddressTypeError: false })
    }
  }

  handleWarningText = fieldName => {
    const warningMaxLengthWarning =
      '*Please note that the courier might only be able to display the first 35 characters.'
    const field = this.form.$(fieldName)
    return field.value && field.value.length > 35 && warningMaxLengthWarning
  }

  handleCloseModal = () => {
    const { toggleModal } = this.props
    if (this.form.isDirty) {
      ToasterNotify.confirm({
        message: <div style={{ whiteSpace: 'break-spaces' }}>{DEFAULT_CONFIRM_MESSAGE}</div>,
        title: 'Caution',
        onConfirm: () => toggleModal(),
      })
    } else {
      toggleModal()
    }
  }

  render() {
    const {
      isModalOpen,
      editMode,
      customerAddressRepository: {
        CRUD: { errors, loading },
      },
    } = this.props
    const { hasAddressTypeError } = this.state
    const formValues = this.form.values()
    return (
      <Modal
        size="lg"
        open={isModalOpen}
        title={`${editMode ? `Edit ` : `Add New `} Address`}
        confirmLabel={`Save`}
        confirmIcon={`save`}
        loading={loading}
        loadingMessage={`Saving please wait...`}
        onConfirm={e => this.form.submit()}
        onClose={this.handleCloseModal}
        hideConfirm={!allowedToOrderRole()}
      >
        <Prompt promptOnExit={this.form.isDirty} />
        <FormContainer title="Address Details" onSubmit={e => this.form.submit()} errors={errors} plain>
          <Row>
            <Col lg={12}>
              <Input field={this.form.$('company')} warningText={this.handleWarningText('company')} />
            </Col>
            <Col lg={6}>
              <CommonSelect field={this.form.$('country')} collectionName="countries" />
            </Col>
            <Col lg={6}>
              <Input field={this.form.$('state')} />
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <Input field={this.form.$('city')} />
            </Col>
            <Col lg={6}>
              <Input field={this.form.$('zipCode')} />
            </Col>
          </Row>
          <Input field={this.form.$('streetAddress1')} />
          <Input field={this.form.$('streetAddress2')} />
          <Row>
            <Col lg={6} className="form-group">
              <label>Address Type</label>
              <div className="d-flex">
                <Checkbox field={this.form.$('billingOnly')} className="mr-3" onChange={this.validateAddressType} />
                <Checkbox field={this.form.$('shippingOnly')} onChange={this.validateAddressType} />
              </div>
              {!!hasAddressTypeError && <small className="form-text text-danger">Address Type is Required.</small>}
              {/* <Checkbox field={this.form.$('presetBilling')} /> */}
            </Col>
            <Col lg={6}>
              <Checkbox
                field={this.form.$('billing')}
                disabled={
                  (formValues.shippingOnly && !formValues.billingOnly) ||
                  (!formValues.billingOnly && !formValues.shippingOnly)
                }
              />
              <Checkbox
                field={this.form.$('shipping')}
                disabled={
                  (formValues.billingOnly && !formValues.shippingOnly) ||
                  (!formValues.billingOnly && !formValues.shippingOnly)
                }
              />
            </Col>
          </Row>
        </FormContainer>
      </Modal>
    )
  }
}

export default decorate(inject('customerAddressRepository', 'commonRepository')(observer(AddressFormModal)), {})
