import React, { Component } from 'react'

import { CustomInput } from 'reactstrap'
import { decorate, toJS } from 'mobx'
import { inject, observer } from 'mobx-react'

import { ACTIVE_STATUS } from 'definitions'
import { allowedToAppAdminRole, Visible } from 'helpers/Auth'
import { titleize } from 'helpers/String'
import Card from 'components/Common/Card'
import ContactFormModal from './ContactFormModal'
import ErrorsList from 'components/Common/ErrorsList'
import IconButton from 'components/Common/Buttons/IconButton'
import ReactTable from 'components/Common/ReactTable'

class Contacts extends Component {
  state = {
    isModalOpen: false,
    isEditMode: false,
    isPromptDelete: false,
    selected: {},
  }
  componentWillUnmount() {
    this.props.customerContactRepository.CRUD.errors = []
  }
  toggleModal = (isEditMode, selected) => {
    const isModalOpen = !this.state.isModalOpen
    this.setState({
      isModalOpen,
      isEditMode,
      selected,
    })
    this.props.onDisplayModeChanged(isModalOpen ? 'contact' : '', selected?.id)
  }
  handleEdit = selected => {
    this.toggleModal(true, selected)
  }
  handToggleLoginFlag = selected => {
    if (selected) {
      const { customer } = this.props
      const payload = {
        ...selected,
        loginFlag: !selected.loginFlag,
      }

      this.props.customerContactRepository.patch(
        payload,
        () => {
          this.table && this.table.reload()
        },
        customer.id
      )
    }
  }
  handToggleStatus = selected => {
    if (selected) {
      const { customer } = this.props
      const payload = {
        ...selected,
        status: selected.status && selected.status === 'INACTIVE' ? 'ACTIVE' : 'INACTIVE',
      }

      this.props.customerContactRepository.patch(
        payload,
        () => {
          this.table && this.table.reload()
        },
        customer.id
      )
    }
  }
  setSelected = selected => {
    this.setState({
      selected: selected,
    })
  }
  render() {
    const { isModalOpen, isEditMode, selected } = this.state
    const { customer } = this.props
    const {
      Table: { data, loading, totalRecordCount, originalData },
      fetchTable,
      CRUD: { errors },
    } = this.props.customerContactRepository

    return (
      <Card
        title="Contacts"
        titleActions={
          <Visible toAppAdminRole>
            <div className="text-right">
              <IconButton title="Add" onClick={() => this.toggleModal(false)} color="dark" outline />
            </div>
          </Visible>
        }
      >
        {!isModalOpen && errors.length ? (
          <div className="mt-3">
            <ErrorsList errors={errors} />
          </div>
        ) : null}
        <ReactTable
          className="striped cell-vertical-center"
          columns={[
            { accessor: 'firstName', Header: 'First Name' },
            {
              accessor: 'lastName',
              Header: 'Last Name',
              Cell: ({ original }) => (
                <div className="d-flex justify-content-between flex-fill align-items-center ellipsis">
                  <span className="ellipsis">{original.lastName}</span>
                  {!!original.sanctioned && (
                    <span
                      title="Contact is sanctioned"
                      style={{ fontSize: '24px' }}
                      className="font-weight-bold text-danger pl-2 pr-3"
                    >
                      !
                    </span>
                  )}
                </div>
              ),
            },
            { accessor: 'email', Header: 'Email' },
            { accessor: 'tel', Header: 'Tel' },
            {
              accessor: 'loginFlag',
              Header: 'Storefront Login',
              width: 125,
              Cell: ({ original }) => (
                <CustomInput
                  type="switch"
                  id={`loginFlag-${original.id}`}
                  name="loginFlag"
                  label={original.loginFlag ? 'Allowed' : 'Not Allowed'}
                  checked={original.loginFlag}
                  onChange={() => {
                    this.handToggleLoginFlag(original)
                  }}
                  disabled={!allowedToAppAdminRole() || original.status !== ACTIVE_STATUS}
                />
              ),
            },
            {
              accessor: 'status',
              Header: 'Status',
              width: 100,
              Cell: ({ original }) => (
                <CustomInput
                  type="switch"
                  id={`status-${original.id}`}
                  name="status"
                  label={titleize(original.status) || 'Inactive'}
                  checked={original.status === ACTIVE_STATUS}
                  onChange={() => {
                    this.handToggleStatus(original)
                  }}
                  disabled={!allowedToAppAdminRole()}
                />
              ),
            },
          ]}
          tableColumnExtensions={[{ columnName: 'name', width: 250 }]}
          data={toJS(data)}
          loadData={params => fetchTable(params, customer.id)}
          loading={loading}
          totalRecordCount={totalRecordCount}
          totalPages={originalData.totalPages}
          ref={ref => (this.table = ref)}
          handleRowClick={({ original }) => (allowedToAppAdminRole() ? this.handleEdit(original) : null)}
          search
        />
        {isModalOpen && (
          <ContactFormModal
            isModalOpen={isModalOpen}
            toggleModal={() => this.toggleModal(true)}
            table={this.table}
            data={selected}
            editMode={isEditMode}
            customer={customer}
            onSubmit={this.props.onSubmit}
          />
        )}
      </Card>
    )
  }
}

export default decorate(inject('customerContactRepository')(observer(Contacts)), {})
