import { inject, observer } from 'mobx-react'
import { toJS } from 'mobx'
import cx from 'classnames'
import React from 'react'

import { allowedToAppAdminRole, Visible } from 'helpers/Auth'
import {
  PRODUCT_NATURE_PHYSICAL,
  PRODUCT_NATURE_VIRTUAL,
  PRODUCT_NATURE_VIRTUAL_NO_SN,
  PRODUCT_NATURE_VIRTUAL_WITH_FSC_ID,
  PRODUCT_NATURE_VIRTUAL_WITH_ORG_ID,
  PRODUCTS_PATH,
} from 'definitions'
import ContentContainer from 'components/Common/ContentContainer'
import ErrorsList from 'components/Common/ErrorsList'
import ToasterNotify from 'helpers/ToasterNotify'

import { getView } from './columns'
import { ReactTableStyled } from './ProductTable.styled'
import Actions from './Actions'
import ChooseColumnsModal from './ChooseColumnsModal'
import EditMoqModal from './EditMoqModal'
import ExportModal from './ExportModal'
import Filters from './Filters'

const pageKey = 'products'
const defaultFilters = {
  showDisabled: true,
}

class ProductTable extends React.Component {
  filters = {}
  selection = []

  constructor(props) {
    super(props)

    const { customerGroupRepository, productRepository, storeRepository, searchRepository } = this.props
    const { view: defaultView, viewLabel, selectedStore, filters } = searchRepository.Pages[pageKey] || {}

    this.state = {
      view: getView({
        key: defaultView || 'general',
        label: viewLabel,
        store: selectedStore,
        productRepository,
        stores: storeRepository.Table.data,
        customerGroups: customerGroupRepository.Table.data,
        handleBulkUpdate: this.handleBulkUpdate,
        allowedToOrderRole: allowedToAppAdminRole,
      }),
      isChoosColumnsModalOpen: false,
      isExportModalOpen: false,
      isEditMoqModalOpen: false,
      isCategoryFilterModalOpen: false,
    }

    this.filters = filters || defaultFilters

    storeRepository.fetchTable({ size: '99', showDisabled: false })
    customerGroupRepository.fetchTable({ size: '99' })
  }

  handleBulkUpdate = async (selection, key, value) => {
    if (selection.length) {
      const propertyKey = key === 'storeDisplay' ? 'storeDisplayInd' : key
      const payload = {
        [propertyKey]: value,
        products: selection.map(id => ({ id })),
      }

      await this.props.productRepository.bulkPost(key, payload)
      this.table.reload()
    } else {
      ToasterNotify.alert({ message: 'Please select products to update', color: 'info' })
    }
  }

  handleChangeView = (key, store) => {
    const { productRepository, storeRepository, customerGroupRepository } = this.props
    const view = getView({
      key,
      store,
      productRepository,
      stores: storeRepository.Table.data,
      customerGroups: customerGroupRepository.Table.data,
      handleBulkUpdate: this.handleBulkUpdate,
      allowedToOrderRole: allowedToAppAdminRole,
    })
    if (key === 'store') {
      this.filters.storeId = store.id
    } else {
      delete this.filters.storeId
    }

    productRepository.Table.data = []
    productRepository.TableListPrice.data = []
    this.updateSearchPage({
      page: 1,
      view: key,
      viewLabel: view.label,
      selectedStore: store,
    })
    this.setState({ view }, () => this.table.reload(false, true))
  }

  handleFilter = (key, value) => {
    if (value || value === false) {
      this.filters[key] = value
    } else {
      delete this.filters[key]
    }
    this.updateSearchPage({ filters: { ...this.filters } })
    this.table && this.table.reload(false, true)
  }

  handleChangeVisibleColumns = view => {
    this.setState({ view })
    this.toggleModal('ChooseColumns')
  }

  handleSelectionChanged = ({ selection }) => {
    this.selection = selection ? Object.keys(selection).map(key => key) : []
  }

  fetchTable = async params => {
    const { productRepository } = this.props
    const { view } = this.state

    const { fetchTable, fetchListPrice } = productRepository

    // update search filer in this.filter
    if (params.search) {
      this.filters.search = params.search
    } else {
      delete this.filters['search']
    }

    // determine the fetch function to use
    let fetchData
    if (view.key === 'general') {
      fetchData = fetchTable
    } else {
      fetchData = fetchListPrice
    }

    // construct payload
    const { productAttributes, productCategories, productSeries, productNature, orderProcess, ...rest } = this.filters
    const filters = { ...rest }
    if (productAttributes) {
      filters.productAttributes = productAttributes.map(item => item.value)?.join(',') || ''
    }
    if (productCategories) {
      filters.productCategories = productCategories.map(item => item.value)?.join(',') || ''
    }
    if (productSeries) {
      filters.productSeries = productSeries.value
    }
    if (orderProcess) {
      filters.orderProcess = orderProcess.value
    }
    if (productNature?.length) {
      const [item] = productNature
      if (item.value !== 'ALL') {
        filters.productNature = productNature.map(item => item.value).join(',')
      }
    }

    return fetchData({ ...params, ...filters })
  }

  showEditMoqModal = () => {
    if (this.selection.length) {
      this.toggleModal('EditMoq')
    } else {
      ToasterNotify.alert({ message: 'Please select products to update', color: 'info' })
    }
  }

  toggleModal = modal => {
    const propertyName = `is${modal}ModalOpen`
    this.setState({ [propertyName]: !this.state[propertyName] })
  }

  updateSearchPage = newValue => {
    const { searchRepository } = this.props
    const values = searchRepository.Pages[pageKey]
    this.props.searchRepository.updateSearchPage(pageKey, {
      ...values,
      ...newValue,
    })
  }

  render() {
    const { view } = this.state
    const {
      productRepository,
      productAttributeRepository,
      storeRepository: {
        Table: { data: stores = [] },
      },
      commonRepository,
      categoryRepository,
      searchCriteria,
      searchRepository,
    } = this.props
    const {
      BulkAction: { loading: bulkActionLoading, errors },
      Table,
      TableListPrice,
    } = productRepository

    let data
    let loading
    let totalRecordCount
    let originalData
    if (view.key === 'general') {
      data = toJS(Table.data)
      loading = Table.loading
      totalRecordCount = Table.totalRecordCount
      originalData = Table.originalData
    } else {
      data = toJS(TableListPrice.data)
      loading = TableListPrice.loading
      totalRecordCount = TableListPrice.totalRecordCount
      originalData = TableListPrice.originalData
    }

    return (
      <ContentContainer>
        {!!errors.length && <ErrorsList errors={errors} />}
        <Filters
          pageKey={pageKey}
          stores={stores}
          commonRepository={commonRepository}
          searchRepository={searchRepository}
          productAttributeRepository={productAttributeRepository}
          selectedView={view.label}
          showEditMoqModal={this.showEditMoqModal}
          handleChangeView={this.handleChangeView}
          handleFilter={this.handleFilter}
          handleBulkUpdate={(key, value) => this.handleBulkUpdate(this.selection, key, value)}
          toggleModal={this.toggleModal}
        />
        <ReactTableStyled
          searchRepository={searchRepository}
          pageKey={pageKey}
          enableRowLink
          getRowUrl={({ original }) => `${PRODUCTS_PATH}/${original.id}`}
          multiSelect={allowedToAppAdminRole()}
          fixedColumns
          className="striped cell-vertical-center product-table horizontal-scroll"
          searchClassName="pr-2 products-search-box"
          actionsClassName="col pl-0 pr-1"
          searchPlaceholder="Search by Product Code or Name"
          columns={[
            {
              headerClassName: 'px-0 product-nature-header',
              accessor: 'productNature',
              resizable: false,
              sortable: false,
              width: 20,
              fixed: 'leftmost',
              getProps: (state, { original = {} } = {}) => ({
                className: cx({
                  'border-info':
                    original.productNatureValue === PRODUCT_NATURE_PHYSICAL || !original.productNatureValue,
                  'border-primary': original.productNatureValue === PRODUCT_NATURE_VIRTUAL,
                  'border-danger': original.productNatureValue === PRODUCT_NATURE_VIRTUAL_NO_SN,
                  'border-success': original.productNatureValue === PRODUCT_NATURE_VIRTUAL_WITH_ORG_ID,
                  'border-secondary': original.productNatureValue === PRODUCT_NATURE_VIRTUAL_WITH_FSC_ID,
                }),
                style: {
                  padding: 0,
                  borderLeft: '5px solid',
                },
              }),
              Cell: () => '',
            },
            {
              Header: 'Product Code',
              accessor: 'productCode',
              fixed: 'left',
              width: 260,
              className: 'px-1',
              Cell: ({ original }) => {
                const productCode = original.tempOrderNumber || original.productCode || 'N/A'
                return (
                  <span title={productCode} className="ellipsis row-clickable">
                    {productCode}
                  </span>
                )
              },
            },
            {
              Header: 'Product Name',
              accessor: 'name',
              minWidth: view.key === 'store' ? 280 : 160,
              Cell: ({ original }) => (
                <span title={original.name} className="pr-2 ellipsis row-clickable">
                  {original.name}
                </span>
              ),
            },
            ...view.columns,
          ]}
          actions={
            <Visible toAppAdminRole>
              <Actions
                commonRepository={commonRepository}
                categoryRepository={categoryRepository}
                searchRepository={searchRepository}
                defaultFilters={defaultFilters}
                pageKey={pageKey}
                handleFilter={this.handleFilter}
                toggleModal={this.toggleModal}
              />
            </Visible>
          }
          sort={{ id: 'name' }}
          data={data}
          loadData={this.fetchTable}
          loading={bulkActionLoading || loading}
          totalRecordCount={totalRecordCount}
          totalPages={originalData.totalPages}
          search
          searchCriteria={searchCriteria}
          ref={ref => (this.table = ref)}
          selectionChanged={this.handleSelectionChanged}
        />
        {this.state.isChooseColumnsModalOpen && (
          <ChooseColumnsModal
            view={view}
            open={this.state.isChooseColumnsModalOpen}
            onConfirm={this.handleChangeVisibleColumns}
            onClose={() => this.toggleModal('ChooseColumns')}
          />
        )}
        {this.state.isExportModalOpen && (
          <ExportModal
            productRepository={productRepository}
            stores={stores}
            filters={this.filters}
            open={this.state.isExportModalOpen}
            onClose={() => this.toggleModal('Export')}
          />
        )}
        {this.state.isEditMoqModalOpen && (
          <EditMoqModal
            open={this.state.isEditMoqModalOpen}
            onClose={() => this.toggleModal('EditMoq')}
            handleSubmit={moq => this.handleBulkUpdate(this.selection, 'moq', moq)}
          />
        )}
      </ContentContainer>
    )
  }
}

export default inject(
  'productRepository',
  'productAttributeRepository',
  'storeRepository',
  'customerGroupRepository',
  'commonRepository',
  'categoryRepository',
  'searchRepository'
)(observer(ProductTable))
