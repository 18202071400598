import React, { Component } from 'react'

import { Button, Col, Row } from 'reactstrap'
import { inject, observer } from 'mobx-react'
import { toJS } from 'mobx'
import _ from 'lodash'
import queryString from 'query-string'

import { formatDecimalValByCustomer, formatOrderPayload, getFormattedDateTimeString } from 'helpers/Formatters'
import { isValidPoNumberFormat } from 'helpers/Validation'
import {
  ORDER_STATUS_APPROVED_QUOTATION,
  ORDER_STATUS_CANCELLED,
  ORDER_STATUS_COMPLETED,
  ORDER_STATUS_CONVERTED,
  ORDER_STATUS_ORDER_PROCESSING,
  PAYMENT_METHOD_BANK_TRANSFER,
  PAYMENT_METHOD_PEPLINK_CREDIT_TERMS_PAYMENT,
  SHIPPING_CARRIER,
  SHIPPING_METHOD_CUSTOMER_PROVIDED,
  SHIPPING_METHOD_PRIORITY_SHIPPING,
} from 'definitions'
import { stripEmptyValues } from 'helpers/Common'
import { Visible } from 'helpers/Auth'
import ActivityLogSidePanel from 'components/LogBook/ActivityLogSidePanel'
import ContentContainer from 'components/Common/ContentContainer'
import Drawer from 'components/Common/Drawer'
import FormContainer from 'components/Common/Form/Container'
import Loader from 'components/Common/Loader'
import ToasterNotify from 'helpers/ToasterNotify'

import { getCreateFields, getFields } from './fieldset'
import MobxForm from 'components/Common/Form/index'

import Actions from './Actions'
import ApprovalRequestModal from './ApprovalRequestModal'
import BillingShippingPanel from './BillingShippingPanel'
import CommentPanel from './CommentPanel'
import CommentSidePanel from './CommentSidePanel'
import ContextMenu from 'components/Common/ContextMenu'
import CoTermCustomerRemarksPanel from './CoTermCustomerRemarksPanel'
import CustomerPanel from './CustomerPanel'
import MailingList from './MailingList'
import OrderSummary from './OrderSummary'
import PaymentPanel from './PaymentPanel'
import ProductsPanel from './ProductsPanel'
import ShippingMethodPanel from './ShippingMethodPanel'

import activityLogIcon from 'images/activity log.svg'
import commentsIcon from 'images/comment.svg'

export const OrderFormFunctions = {
  computeOrderSummary: async (
    updateShippingTotal = undefined,
    updatePayment = true,
    updateShippingInsurance = true,
    self
  ) => {
    const orderItems = self.props.orderRepository.CRUD.data.orderItems
    const payload = {
      ...self.form.values(),
      orderItems,
      updateShippingTotal,
      updateSurcharge: updatePayment,
      updateShippingInsurance,
      manualShippingFeeInd: false,
    }
    const { step } = self.props
    const customer = self.form.$('customer').value

    //remove empty values from the root level object
    stripEmptyValues(payload)
    stripEmptyValues(payload.billingAddress)
    stripEmptyValues(payload.shippingAddress)

    await self.props.orderRepository.orderSummary(formatOrderPayload(payload), result => {
      if (step === 2) {
        if (updateShippingTotal) {
          if (self.form.has('manualShippingFeeInd')) {
            self.form.$('manualShippingFeeInd').set(false)
          }
          self.form.$('shippingMethod.totalShippingFee').set(formatDecimalValByCustomer(result.shippingTotal, customer))
        }
        if (updateShippingInsurance) {
          self.form
            .$('shippingMethod.shippingInsurance')
            .set(result.shippingInsurance ? formatDecimalValByCustomer(result.shippingInsurance, customer) : '')
        }
        if (updatePayment) {
          self.form
            .$('payment.surcharge')
            .set(result.paymentCharge ? formatDecimalValByCustomer(result.paymentCharge, customer) : '')
        }
      }
    })
  },
  updateOrderSummary: (orderSummaryData, self) => {
    self.props.orderRepository.OrderRates.data = orderSummaryData
  },
  setFieldValidations: (unset = false, formAction, self) => {
    const {
      orderRepository: { CRUD },
    } = self.props

    const virtualProductOnlyInd = CRUD.data && (CRUD.data.virtualProductOnlyInd || false)
    const shippingFields = [
      'shippingAddress.firstName',
      'shippingAddress.lastName',
      'shippingAddress.email',
      'shippingAddress.streetAddress1',
      'shippingAddress.city',
      'shippingAddress.country',
      'shippingAddress.zipCode',
      'shippingMethod.method',
    ]

    let requiredFields = [
      'billingAddress.firstName',
      'billingAddress.lastName',
      'billingAddress.email',
      'billingAddress.streetAddress1',
      'billingAddress.city',
      'billingAddress.country',
      'billingAddress.zipCode',
      ...shippingFields,
      'payment.paymentMethod',
    ]
    const paymentMethod = self.form.$('payment.paymentMethod').value

    //reset rules
    self.form.$('shippingMethod.shippingCarrier').set('rules', null)
    self.form.$('shippingMethod.accountNo').set('rules', null)
    self.form.$('shippingMethod.contactInfo').set('rules', null)
    requiredFields.forEach(field => {
      self.form.$(field).set('rules', null)
    })

    const rules = !unset ? 'required' : ''
    requiredFields
      .filter(
        rf =>
          !(formAction === 'quotation' && rf === 'payment.paymentMethod') &&
          !(!!virtualProductOnlyInd && shippingFields.includes(rf))
      )
      .forEach(field => {
        self.form.$(field).set('rules', rules)
      })

    self.form.$('billingAddress.telNo').set('rules', rules)

    if (!virtualProductOnlyInd) {
      if (self.form.$('shippingMethod.method').value === SHIPPING_METHOD_PRIORITY_SHIPPING) {
        self.form.$('shippingMethod.shippingCarrier').set('rules', rules)
      }
      if (self.form.$('shippingMethod.method').value === SHIPPING_METHOD_CUSTOMER_PROVIDED) {
        self.form.$('shippingMethod.accountNo').set('rules', rules)
        self.form.$('shippingMethod.contactInfo').set('rules', rules)
      }
      self.form.$('shippingAddress.telNo').set('rules', rules)
      if (unset) {
        self.form.$('billingAddress.streetAddress1').set('rules', '')
        self.form.$('billingAddress.streetAddress2').set('rules', '')
        self.form.$('shippingAddress.streetAddress1').set('rules', '')
        self.form.$('shippingAddress.streetAddress2').set('rules', '')
      } else {
        self.form.$('billingAddress.streetAddress1').set('rules', `${rules}|max:35`)
        self.form.$('billingAddress.streetAddress2').set('rules', `max:35`)
        self.form.$('shippingAddress.streetAddress1').set('rules', `${rules}|max:35`)
        self.form.$('shippingAddress.streetAddress2').set('rules', `max:35`)
      }

      const shippingCarrier = self.form.$('shippingMethod.shippingCarrier').value?.value
      if (shippingCarrier !== SHIPPING_CARRIER.US_VFT_ONLY_SELF_PICK_UP) {
        self.form.$('poNumber').set('rules', '')
      }
    } else {
      self.form.$('poNumber').set('rules', '')

      self.form.$('shippingAddress.telNo').set('rules', '')
      if (unset) {
        self.form.$('shippingAddress.streetAddress1').set('rules', '')
        self.form.$('shippingAddress.streetAddress2').set('rules', '')
        self.form.$('billingAddress.streetAddress1').set('rules', '')
        self.form.$('billingAddress.streetAddress2').set('rules', '')
      } else {
        self.form.$('billingAddress.streetAddress1').set('rules', `${rules}|max:35`)
        self.form.$('billingAddress.streetAddress2').set('rules', 'max:35')
        self.form.$('shippingAddress.streetAddress1').set('rules', '')
        self.form.$('shippingAddress.streetAddress2').set('rules', '')
      }
    }

    if (paymentMethod && paymentMethod.value === PAYMENT_METHOD_BANK_TRANSFER) {
      self.form.$('payment.bankAccount').set('rules', rules)
    } else {
      self.form.$('payment.bankAccount').set('rules', '')
    }

    if (paymentMethod && paymentMethod.value === PAYMENT_METHOD_PEPLINK_CREDIT_TERMS_PAYMENT) {
      self.form.$('payment.paymentTerms').set('rules', rules)
    } else {
      self.form.$('payment.paymentTerms').set('rules', '')
    }
  },
  validateForm: self => {
    self.handleFormError(self.form)
    self.form.validate({ showErrors: true }).then(({ isValid }) => {
      if (isValid) {
        self.toggleApprovalRequestModal()
      } else {
        self.handleFormError(self.form)
      }
    })
  },

  updateBankAccount: async (country, self) => {
    const paymentMethod = self.form.$('payment.paymentMethod').value
    if (
      paymentMethod &&
      (paymentMethod.value === PAYMENT_METHOD_BANK_TRANSFER ||
        paymentMethod.value === PAYMENT_METHOD_PEPLINK_CREDIT_TERMS_PAYMENT)
    ) {
      const { data, errors } = await self.props.bankAccountRepository.getBankAccountsByOrder(
        formatOrderPayload(self.form.values())
      )
      if (data && !errors.length) {
        self.form.$('payment.bankAccount').set(data)
      }
    }
  },
}
class OrderForm extends Component {
  isToggleSaveQuotationModal = false
  payload = {}
  formAction = ''
  constructor(props) {
    super(props)
    const { edit } = queryString.parse(props.location.search)
    const order = props.orderRepository.CRUD.data
    const data = order ? toJS(order) : {}
    const { id } = data
    const step = props.step
    const fields =
      step === 2
        ? getFields({
            data,
            requireQuotationDaysValidFor: fieldData => this.requireQuotationDaysValidFor(fieldData),
            validatePoNumber: fieldData => this.validatePoNumber(fieldData),
          })
        : getCreateFields()

    this.form = new MobxForm(
      { fields },
      {
        name: 'OrderForm',
        hooks: {
          onSuccess: form => {
            if (step === 2) {
              if (this.isToggleSaveQuotationModal) {
                this.toggleSaveQuotationModal()
              } else {
                this.handleSubmit(form)
              }
            } else {
              this.createDraftOrder(form)
            }
            this.isToggleSaveQuotationModal = false
          },
          onError: this.handleFormError,
        },
      }
    )

    const isEdit =
      step === 2 &&
      edit === 'true' &&
      ![ORDER_STATUS_ORDER_PROCESSING, ORDER_STATUS_CANCELLED, ORDER_STATUS_COMPLETED, ORDER_STATUS_CONVERTED].includes(
        data.status
      )

    this.state = {
      step,
      formErrors: null,
      activeCommentPanelType: '',
      isSaveQuotationModalOpen: false,
      isApprovalRequestModalOpen: false,
      readOnly: !isEdit,
      skipPromptOnExit: false,
    }

    this.computeOrderSummary = _.debounce(this.computeOrderSummary, 350, { leading: false, trailing: true }).bind(this)

    if (id) {
      this.updateOrderSummary(order.orderSummary)
    }
  }
  componentDidUpdate(prevProps) {
    // const { step } = this.props
    const errors = toJS(this.props.orderRepository.CRUD.errors)
    const prevErrors = toJS(prevProps.orderRepository.CRUD.errors)
    const statusErrors = toJS(this.props.orderRepository.OrderStatus.errors)
    const prevStatusErrors = toJS(this.props.orderRepository.OrderStatus.errors)
    const invalidateField = err =>
      err.fieldName && this.form.has(err.fieldName) && this.form.$(err.fieldName).invalidate(err.message)

    if (errors !== prevErrors) {
      errors.filter(invalidateField)
    }
    if (statusErrors !== prevStatusErrors) {
      statusErrors.filter(invalidateField)
    }

    //Note to any developer.
    // eslint-disable-next-line max-len
    //If input is type number, setting a value with group formatting, will set textbox value to empty instead of the formatted value.

    // const customer = this.form.$('customer').value
    // if (step === 2) {
    //   if (this.form.$('shippingMethod.totalShippingFee').value) {
    //     this.form
    //       .$('shippingMethod.totalShippingFee')
    //       .set(formatCustomerCurrency(this.form.$('shippingMethod.totalShippingFee').value, customer))
    //   }
    //   if (this.form.$('shippingMethod.shippingInsurance').value) {
    //     this.form
    //       .$('shippingMethod.shippingInsurance')
    //       .set(formatCustomerCurrency(this.form.$('shippingMethod.shippingInsurance').value, customer))
    //   }
    //   if (this.form.$('payment.surcharge').value) {
    // eslint-disable-next-line max-len
    //     this.form.$('payment.surcharge').set(formatCustomerCurrency(this.form.$('payment.surcharge').value, customer))
    //   }
    // }
  }
  gotoOrders = ({ orderId = '', query = '', useQuotation = false, skipPromptOnExit } = {}) => {
    const { quotation } = this.props
    const isQuotation = useQuotation || quotation

    if (skipPromptOnExit) this.setState({ readOnly: true })
    this.props.history.push(
      `${isQuotation ? '/quotations' : '/orders'}${orderId ? `/${orderId}` : ''}${query ? query : ''}`
    )
  }
  handleEditMode = () => {
    const { data } = this.props

    if (data.hasConsolidatedStatusShipment && !data.virtualProductOnlyInd) {
      this.setState({
        formErrors: [
          {
            message:
              // eslint-disable-next-line max-len
              'Order contains consolidated shipment, not available to change. Please cancel order and re-create if required.',
          },
        ],
      })
    } else {
      this.setState({ readOnly: false })
    }
  }
  handleCancelOrder = () => {
    const {
      orderRepository: {
        cancelOrder,
        CRUD: { data },
      },
    } = this.props
    ToasterNotify.confirm({
      message: 'Are you sure you want to cancel this order?',
      title: 'Warning',
      onConfirm: async () => {
        const { errors } = await cancelOrder(data.id)
        if (!errors || !errors.length) {
          this.gotoOrders({ skipPromptOnExit: true })
        }
      },
    })
  }
  handleCloneOrder = async () => {
    const {
      orderRepository: {
        cloneOrder,
        CRUD: { data },
      },
      quotation,
    } = this.props

    this.setState({ formErrors: null })

    const { data: cloneData, errors } = await cloneOrder(data.id)
    if (!errors || !errors.length) {
      this.form.set({
        ...cloneData,
        ...(cloneData.orderMailing
          ? {
              orderMailing: {
                ...cloneData.orderMailing,
                billings: cloneData.orderMailing.billings ? cloneData.orderMailing.billings.join('\n') : null,
                shippings: cloneData.orderMailing.shippings ? cloneData.orderMailing.shippings.join('\n') : null,
              },
            }
          : {}),
      })
      this.gotoOrders({ orderId: cloneData.id, quotation })
    }
  }
  handleFormError = form => {
    this.setState({
      formErrors: form.hasError
        ? [{ message: 'There are validation errors in this order. Please review your order' }]
        : null,
    })
  }
  handleSendProformaInvoice = async () => {
    await this.props.orderRepository.sendProformaInvoice(this.props.data.id)
    if (!this.props.orderRepository.CRUD.errors.length) {
      ToasterNotify.alert({ message: 'Proforma invoice is sent successfully!' })
    }
  }
  handleSubmitAsOrder = () => {
    this.formAction = 'submitAsOrder'
    this.form.submit()
  }
  handleSubmit = form => {
    const values = { ...toJS(form.values()) }

    const {
      quotation,
      orderRepository: {
        patchQuotation,
        saveAsDraft,
        saveOrder,
        create,
        orderRevision,
        submitAsOrder,
        CRUD: { data },
        OrderRates,
      },
    } = this.props
    const payload = {
      ...data,
      ...values,
      payment: {
        ...values.payment,
        additionalCharge: OrderRates.data.additionalCharge || 0,
      },
    }

    const type = this.formAction
    const useQuotation = type === 'quotation' || quotation
    const submit = () => {
      if (data.id) {
        let doPatch
        if (type === 'submitAsOrder') {
          doPatch = submitAsOrder
        } else if (type === 'draft') {
          doPatch = saveAsDraft
        } else if (type === 'quotation' || quotation) {
          doPatch = patchQuotation
        } else {
          doPatch = saveOrder
        }

        doPatch(
          formatOrderPayload(payload),
          () => {
            if (type === 'submitAsOrder') {
              this.setState({ readOnly: true })
              this.props.history.push('/orders')
            } else {
              let query = ''
              const { status } = queryString.parse(this.props.location.search)

              if (status) {
                query = `?status=${status.toUpperCase()}`
              } else if (data.status === 'PENDING_APPROVAL' && !useQuotation) {
                query = `?status=${data.status}`
              }
              this.gotoOrders({ query, useQuotation, skipPromptOnExit: true })
            }
          },
          data.id
        )
      } else
        create(formatOrderPayload(payload), () => {
          this.setState({ skipPromptOnExit: true })
          this.gotoOrders({})
        })
    }
    if (!useQuotation && data.orderNumber) {
      ToasterNotify.confirm({
        message: 'Are you sure? This order will be canceled and a new order will be created.',
        title: 'Warning',
        onConfirm: () => {
          orderRevision(data.id, res => {
            this.gotoOrders({ skipPromptOnExit: true })
          })
        },
      })
    } else submit()
  }
  handleSendEmail = async () => {
    this.setState({ formErrors: null })
    const { errors } = await this.props.orderRepository.sendEmail(this.props.data.id)
    if (!errors.length) {
      ToasterNotify.alert({ message: 'Email sent' })
    }
  }
  handleOpenDrawer = type => {
    this.setState({ activeCommentPanelType: type }, () => {
      this.drawer && this.drawer.openDrawer('comments')
    })
  }
  handleCloseDrawer = () => {
    this.drawer && this.drawer.closeDrawer()
  }
  handleDiscardChanges = () => {
    const {
      match: { params },
      orderRepository: { getById },
    } = this.props

    ToasterNotify.confirm({
      message: `Are you sure you want to Discard the changes?`,
      title: 'Warning',
      onConfirm: () => {
        getById(params.orderId, () => {
          this.form.reset()
          this.computeOrderSummary(false)
          this.setState({ readOnly: true })
        })
      },
    })
  }
  createDraftOrder = form => {
    const { customer, store, poNumber, orderType, skipSNWarrantyInd, orderNumberSource } = form.values()
    const payload = {
      customer,
      poNumber,
      orderType,
      store: store && {
        id: store.id,
      },
      skipSNWarrantyInd,
      ...(orderType && !orderType.counterAdjustmentInd && { orderNumberSource }),
    }
    stripEmptyValues(payload)
    this.props.orderRepository.createDraftOrder(formatOrderPayload(payload), res => {
      this.setState({ skipPromptOnExit: true })
      this.props.history.push(`/orders/${res.id}?status=new&edit=true`)
    })
  }
  computeOrderSummary = (updateShippingTotal, updatePayment, updateShippingInsurance) =>
    OrderFormFunctions.computeOrderSummary(updateShippingTotal, updatePayment, updateShippingInsurance, this)

  updateOrderSummary = orderSummaryData => OrderFormFunctions.updateOrderSummary(orderSummaryData, this)

  requireQuotationDaysValidFor = ({ field }) => {
    if (this.state && this.state.isSaveQuotationModalOpen) {
      return [field.value > 0, 'This field is required']
    }
    return [true]
  }

  validatePoNumber = ({ field, form }) => {
    const {
      orderRepository: {
        CRUD: { data },
      },
      quotation,
    } = this.props

    if (!quotation && !data.virtualProductOnlyInd) {
      const shippingCarrier = form.$('shippingMethod.shippingCarrier').value?.value
      if (shippingCarrier === SHIPPING_CARRIER.US_VFT_ONLY_SELF_PICK_UP) {
        if (field.value) {
          if (!isValidPoNumberFormat(field.value)) {
            return [false, 'PO Number format is wrong, please check again. e.g. SO123456']
          }
        } else {
          return [false, 'PO Number is missing, please check again.']
        }
      }
    }
    return [true]
  }

  toggleApprovalRequestModal = () => {
    this.setState({ isApprovalRequestModalOpen: !this.state.isApprovalRequestModalOpen })
  }
  setFieldValidations = (unset = false, formAction) => OrderFormFunctions.setFieldValidations(unset, formAction, this)
  validateForm = () => OrderFormFunctions.validateForm(this)
  updateBankAccount = country => OrderFormFunctions.updateBankAccount(country, this)
  render() {
    const {
      breadcrumbs,
      title,
      commonRepository,
      customerRepository,
      customerAddressRepository,
      customerContactRepository,
      storeRepository,
      orderRepository: {
        CRUD: { errors, loading },
        CRUD,
        OrderStatus,
      },
      orderRepository,
      sanctionCustomerRepository,
      bankAccountRepository,
      fileUploadRepository,
      quotation,
      data = {},
    } = this.props
    const selectedCustomer = this.form.$('customer').value
    const { step, isApprovalRequestModalOpen, readOnly } = this.state
    let { formErrors } = this.state

    const repositoryErrors = errors.filter(err => !err.fieldName) || OrderStatus.errors.filter(err => !err.fieldName)
    const repositoryValidationError =
      errors.some(err => err.fieldName != null) || OrderStatus.errors.some(err => err.fieldName != null)
        ? [{ message: 'There are validation errors in this order. Please review your order' }]
        : null
    const isShippingApplicable = CRUD.data && CRUD.data.virtualProductOnlyInd
    const orderType = this.form.$('orderType').value || {}
    const blacklisted = selectedCustomer && selectedCustomer.blacklisted

    const isSubmitting =
      orderRepository.CRUD.submitting || orderRepository.ApprovalRequest.submitting || fileUploadRepository.submitting
    const isPromptOnExit = isSubmitting || (step === 2 ? !readOnly : !this.state.skipPromptOnExit && this.form.isDirty)

    return (
      <React.Fragment>
        <div className="content">
          <ContentContainer
            promptOnExit={isPromptOnExit}
            promptOnExitIsConfirm={!isSubmitting}
            breadcrumbs={breadcrumbs}
            title={
              <div className="d-inline-flex align-items-start">
                <h4 className="m-0">{readOnly ? title : <>Edit {title}</>}</h4>
                {data.orderSource && (
                  <span
                    className="btn btn-sm ml-4 px-4"
                    style={{ backgroundColor: 'rgba(0,0,0,0.1)', fontSize: '0.75rem' }}
                  >
                    {data.orderSource}
                  </span>
                )}
              </div>
            }
            actions={
              step === 2 && (
                <Actions
                  commonRepository={commonRepository}
                  data={data}
                  form={this.form}
                  quotation={quotation}
                  readOnly={readOnly}
                  blacklisted={blacklisted}
                  loading={loading}
                  handleEditMode={this.handleEditMode}
                  handleSaveOrder={({ isToggleSaveQuotationModal }) => {
                    if (isToggleSaveQuotationModal) {
                      this.formAction = 'quotation'
                      this.setFieldValidations(false, 'quotation')
                      this.validateForm()
                    } else {
                      this.formAction = 'submit'
                      this.setFieldValidations()
                      this.validateForm()
                    }
                  }}
                  handleCloneOrder={this.handleCloneOrder}
                  handleCancelOrder={this.handleCancelOrder}
                  handleDiscardChanges={this.handleDiscardChanges}
                  handleSaveDraft={() => {
                    this.formAction = 'draft'
                    this.setFieldValidations(true)
                    this.form.submit()
                  }}
                  handleSubmitAsOrder={this.handleSubmitAsOrder}
                  handleSendProformaInvoice={this.handleSendProformaInvoice}
                  handleSendEmail={this.handleSendEmail}
                />
              )
            }
            titleClassName="col-auto"
            actionsClassName="mb-2 col-auto ml-auto pl-0"
          >
            <Row>
              <Col sm={12}>
                <FormContainer
                  title="Order Details"
                  onSubmit={() => this.handleSubmit(this.form)}
                  errors={formErrors || repositoryValidationError || repositoryErrors}
                  plain
                >
                  <Row>
                    <Col sm={data.id ? 12 : 4}>
                      <CustomerPanel
                        form={this.form}
                        orderRepository={orderRepository}
                        customerRepository={customerRepository}
                        commonRepository={commonRepository}
                        storeRepository={storeRepository}
                        sanctionCustomerRepository={sanctionCustomerRepository}
                        step={step}
                        computeOrderSummary={this.computeOrderSummary}
                        readOnly={readOnly}
                      />
                      {this.state.step === 1 ? (
                        <div className="text-right">
                          <Button
                            disabled={!selectedCustomer || blacklisted || orderRepository.CRUD.loading}
                            color="primary"
                            onClick={() => {
                              this.formAction = 'draft'
                              this.form.submit()
                            }}
                          >
                            Save
                          </Button>
                        </div>
                      ) : null}
                    </Col>
                  </Row>
                  {step === 2 && (
                    <>
                      <ProductsPanel
                        form={this.form}
                        orderSource={data.orderSource}
                        quotation={quotation}
                        computeOrderSummary={this.computeOrderSummary}
                        readOnly={readOnly || blacklisted}
                      />
                      <Row>
                        <Col sm="9">
                          <BillingShippingPanel
                            readOnly={readOnly || blacklisted}
                            form={this.form}
                            commonRepository={commonRepository}
                            customerContactRepository={customerContactRepository}
                            customerAddressRepository={customerAddressRepository}
                            orderRepository={orderRepository}
                            copyBilling={this.copyBilling}
                            toggleBillingContact={this.toggleBillingContact}
                            toggleBillingAddress={this.toggleBillingAddress}
                            computeOrderSummary={this.computeOrderSummary}
                            isShippingApplicable={isShippingApplicable}
                            customer={selectedCustomer}
                            updateBankAccount={this.updateBankAccount}
                          />
                        </Col>
                        <Col sm="3">
                          <ShippingMethodPanel
                            readOnly={readOnly || blacklisted}
                            form={this.form}
                            commonRepository={commonRepository}
                            storeRepository={orderRepository}
                            computeOrderSummary={this.computeOrderSummary}
                            isShippingApplicable={isShippingApplicable}
                          />
                          <PaymentPanel
                            orderSource={data.orderSource}
                            orderType={data.orderType}
                            readOnly={readOnly || blacklisted}
                            form={this.form}
                            commonRepository={commonRepository}
                            orderRepository={orderRepository}
                            bankAccountRepository={bankAccountRepository}
                            computeOrderSummary={this.computeOrderSummary}
                            summary={orderRepository.OrderRates.data}
                            updateBankAccount={this.updateBankAccount}
                          />
                        </Col>
                      </Row>
                      <Row className="mb-4">
                        <Col sm="7">
                          <CommentPanel
                            readOnly={readOnly || blacklisted}
                            field={this.form.$('customerComments')}
                            label="Customer Comments"
                            buttonColor="success"
                            className="mb-2"
                            handleOpenDrawer={() => this.handleOpenDrawer('CUSTOMER')}
                          />
                          <CommentPanel
                            readOnly={readOnly || blacklisted}
                            field={this.form.$('internalRemarks')}
                            label="Internal Remarks"
                            buttonColor="secondary"
                            className="mb-2"
                            handleOpenDrawer={() => this.handleOpenDrawer('INTERNAL')}
                          />
                          <MailingList data={data} readOnly={readOnly || blacklisted} form={this.form} />
                          <CoTermCustomerRemarksPanel
                            readOnly={readOnly}
                            field={this.form.$('coTermCustomerRemarks')}
                          />
                        </Col>
                        <Col sm="5" className="d-flex">
                          <OrderSummary form={this.form} storeRepository={orderRepository} />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="6" className="d-flex align-items-end">
                          <small>{`Created by ${data.createdBy} ${getFormattedDateTimeString(
                            data.createdDate
                          )}`}</small>
                        </Col>
                        <Visible toOrderProcessRole>
                          <Col xs="6" className="d-flex justify-content-end">
                            {loading && <Loader text="" />}
                            {!loading && data.status !== 'CANCELLED' && (
                              <ContextMenu
                                disabled={(readOnly && !quotation) || blacklisted}
                                dropDownToggle="Actions"
                                menus={
                                  quotation
                                    ? [
                                        {
                                          text: 'Save Draft',
                                          onClick: () => {
                                            this.formAction = 'draft'
                                            this.setFieldValidations(true)
                                            this.form.submit()
                                          },
                                          hidden: readOnly || data.status === ORDER_STATUS_APPROVED_QUOTATION,
                                        },
                                        {
                                          text: 'Submit as Quotation',
                                          onClick: () => {
                                            this.formAction = 'quotation'
                                            this.setFieldValidations(false, 'quotation')
                                            this.validateForm()
                                          },
                                          hidden: readOnly && data.status === ORDER_STATUS_APPROVED_QUOTATION,
                                        },
                                        {
                                          text: 'Convert to Order',
                                          onClick: this.handleSubmitAsOrder,
                                          hidden: data.status !== ORDER_STATUS_APPROVED_QUOTATION,
                                        },
                                      ]
                                    : [
                                        {
                                          text: 'Save Draft',
                                          onClick: () => {
                                            this.formAction = 'draft'
                                            this.setFieldValidations(true)
                                            this.form.submit()
                                          },
                                          hidden: data.status === 'PENDING_PAYMENT',
                                        },
                                        {
                                          text: 'Submit Order',
                                          onClick: () => {
                                            this.formAction = 'submit'
                                            this.setFieldValidations()
                                            this.validateForm()
                                          },
                                        },
                                        {
                                          text: 'Save As Quotation',
                                          onClick: () => {
                                            this.formAction = 'quotation'
                                            this.setFieldValidations(false, 'quotation')
                                            this.validateForm()
                                          },
                                          hidden: !(data.status === 'NEW' && orderType.name === 'Store'),
                                        },
                                      ]
                                }
                              />
                            )}
                          </Col>
                        </Visible>
                      </Row>
                    </>
                  )}
                </FormContainer>
              </Col>
            </Row>
          </ContentContainer>
        </div>
        {step === 2 && (
          <Drawer
            ref={ref => (this.drawer = ref)}
            tabs={[
              {
                key: 'logs',
                icon: <img src={activityLogIcon} alt="Acitivity Logs" />,
                content: (
                  <ActivityLogSidePanel entity="Order" entityId={data.id} closeDrawer={this.handleCloseDrawer} />
                ),
              },
              {
                key: 'comments',
                icon: <img src={commentsIcon} alt="Comments" />,
                content: (
                  <CommentSidePanel
                    orderId={data.id}
                    activeType={this.state.activeCommentPanelType}
                    handleCloseDrawer={this.handleCloseDrawer}
                    form={this.form}
                  />
                ),
              },
            ]}
          />
        )}
        {isApprovalRequestModalOpen && (
          <ApprovalRequestModal
            form={this.form}
            order={CRUD.data}
            open={isApprovalRequestModalOpen}
            onConfirm={this.gotoOrders}
            onClose={() => {
              this.setFieldValidations(false)
              this.toggleApprovalRequestModal()
            }}
            formAction={this.formAction}
          />
        )}
      </React.Fragment>
    )
  }
}

export default inject(
  'orderRepository',
  'orderItemRepository',
  'commonRepository',
  'customerRepository',
  'customerAddressRepository',
  'customerContactRepository',
  'customerGroupRepository',
  'customerGroupDetailsRepository',
  'storeRepository',
  'shippingRateRepository',
  'sanctionCustomerRepository',
  'bankAccountRepository',
  'fileUploadRepository'
)(observer(OrderForm))
