/* @flow */
import { inject, observer } from 'mobx-react'
import { toJS } from 'mobx'
import ContentContainer from 'components/Common/ContentContainer'
import React, { Component } from 'react'

import { Button } from 'reactstrap'
import { createFields } from './fieldset'
import { formatDate } from 'helpers/Formatters'
import { ORDER_ADVANCED_SEARCH_PATH } from 'definitions'
import ErrorsList from 'components/Common/ErrorsList'
import Form from 'components/Common/Form'
import SearchForm from './SearchForm'
import TableResult from './TableResult'

const pageKey = 'order-advanced-search'

class OrderAdvanceSearch extends Component {
  constructor(props) {
    super(props)

    const fields = createFields()
    this.form = new Form({ fields }, { name: 'OrderAdvanceSearchForm', hooks: { onSuccess: this.handleSubmit } })

    this.state = {
      errors: [],
      mode: 'search',
    }

    this.initializeDefaultFilters()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.state?.mode) {
      if (this.props.location.state?.mode !== nextProps.location.state?.mode) {
        this.initializeDefaultFilters(nextProps.location.state?.mode)
      }
    }
  }

  componentWillUnmount() {
    this.props.orderAdvanceSearchRepository.clearErrorMessages()
  }

  initializeDefaultFilters = modeOverride => {
    const { searchRepository } = this.props
    const defaultFilters = searchRepository.Pages[pageKey]?.filters
    if (defaultFilters) {
      const { mode, ...defaultValues } = toJS(defaultFilters)
      this.form.set(defaultValues)

      const newMode = modeOverride || mode
      this.state = { ...this.state, mode: newMode }
    }
  }

  loadData = async pageableParam => {
    const { orderAdvanceSearchRepository } = this.props

    const values = this.form.values()
    const {
      orderNumber,
      customerName,
      email,
      customerGroupId,
      store,
      orderTypeId,
      orderDateFrom,
      orderDateTo,
      orderSource,
      status,
      productCode,
      productName,
      orgId,
      fscId,
      serialNumber,
      dealId,
      referralCode,
      couponCode,
      paymentMethodId,
      noShipmentInd,
    } = values

    const pageable = pageableParam ?? { page: 0, size: 10, sort: 'createdDate,desc' }

    const payload = {
      pageable,
      ...(orderNumber ? { orderNumber } : {}),
      ...(customerName ? { customerName } : {}),
      ...(email ? { email } : {}),
      ...(customerGroupId ? { customerGroupId: customerGroupId.id } : {}),
      ...(store ? { storeId: store.id } : {}),
      ...(orderTypeId ? { orderTypeId: orderTypeId.id } : {}),
      ...(orderDateFrom ? { orderDateFrom: formatDate(orderDateFrom, 'YYYY-MM-DD') } : {}),
      ...(orderDateTo ? { orderDateTo: formatDate(orderDateTo, 'YYYY-MM-DD') } : {}),
      ...(orderSource ? { orderSource: orderSource.value } : {}),
      ...(status ? { status: status.value } : {}),
      ...(productCode ? { productCode } : {}),
      ...(productName ? { productName } : {}),
      ...(orgId ? { orgId } : {}),
      ...(fscId ? { fscId } : {}),
      ...(serialNumber ? { serialNumber } : {}),
      ...(dealId ? { dealId } : {}),
      ...(referralCode ? { referralCode } : {}),
      ...(couponCode ? { couponCode } : {}),
      ...(paymentMethodId ? { paymentMethodId: paymentMethodId.id } : {}),
      ...(noShipmentInd ? { noShipmentInd } : {}),
    }

    // save setting
    this.updateSearchPage(values, {
      page: pageable.page + 1,
      pageSize: pageable.size,
      sort: pageable.sort?.id ? `${pageable.sort.id},${pageable.sort.desc ? 'desc' : 'asc'}` : null,
    })

    // do search
    await orderAdvanceSearchRepository.fetchTable(payload)
    if (orderAdvanceSearchRepository.Table.errors?.length) {
      this.setSearchErrors(orderAdvanceSearchRepository.Table.errors)
    } else {
      this.handleSwitchMode('result')
    }
  }

  updateSearchPage = (newFilters, pageable) => {
    const { searchRepository } = this.props
    const filters = searchRepository.Pages[pageKey]?.filters || {}
    searchRepository.updateSearchPage(pageKey, {
      ...pageable,
      filters: {
        ...filters,
        ...newFilters,
      },
    })
  }

  setSearchErrors = dataErrors => {
    const errors = []

    if (dataErrors?.length) {
      dataErrors.forEach(error => {
        if (error.fieldName && this.form.has(error.fieldName)) {
          this.form.$(error.fieldName).invalidate(error.message)
        } else {
          errors.push(error)
        }
      })
    }
    this.setState({ errors })
  }

  handleReset = () => {
    this.form.reset()
  }

  handleSwitchMode = mode => {
    const previousState = this.state.mode || 'search'
    this.updateSearchPage({ mode })
    this.setState({ mode }, () => {
      this.props.history.replace(ORDER_ADVANCED_SEARCH_PATH, {
        mode: previousState,
      })
      this.props.history.push(ORDER_ADVANCED_SEARCH_PATH, { mode })
    })
  }

  render() {
    const { errors = [], mode } = this.state
    const isResultMode = mode === 'result'

    return (
      <ContentContainer
        title={isResultMode ? 'Advanced Orders Search Result' : 'Advanced Orders Search'}
        breadcrumbs={[
          {
            label: 'Home',
            href: '/',
          },
          {
            label: 'Advanced Orders Search',
            onClick: () => this.handleSwitchMode('search'),
          },
          ...(isResultMode ? [{ label: 'Result' }] : []),
        ]}
        promptOnExit={this.props.orderAdvanceSearchRepository.Table.loading}
        promptOnExitIsConfirm={false}
        actions={
          mode == 'result' && (
            <Button
              type="button"
              color="primary"
              title="Go back to search form"
              onClick={() => this.handleSwitchMode('search')}
            >
              Go Back
            </Button>
          )
        }
      >
        {!!errors?.length && <ErrorsList errors={errors} />}
        {mode !== 'result' && (
          <SearchForm
            form={this.form}
            switchMode={this.handleSwitchMode}
            onSearch={this.loadData}
            onReset={this.handleReset}
          />
        )}
        {mode === 'result' && (
          <TableResult pageKey={pageKey} loadData={this.loadData} switchMode={this.handleSwitchMode} />
        )}
      </ContentContainer>
    )
  }
}

export default inject('orderAdvanceSearchRepository', 'searchRepository')(observer(OrderAdvanceSearch))
