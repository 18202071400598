import { CustomInput } from 'reactstrap'
import styled from 'styled-components'

import ReactSelect from 'components/Common/Form/ReactSelect'
import ReactTable from 'components/Common/ReactTable'

export const ActionsStyled = styled.div`
  font-size: 11px;
`

export const CustomInputStyled = styled(CustomInput)`
  &&& {
    label {
      white-space: pre;
    }
  }
`

export const ReactTableStyled = styled(ReactTable)`
  &&& {
    .product-nature-header:after {
      margin-left: 0;
    }
  }
`

export const ReactSelectStyled = styled(ReactSelect)`
  .react-select {
    &__control {
      width: ${props => props.width || '180px'};
      border: none;

      &.category-select {
        &:active {
          box-shadow: 0 0 0 0.2rem rgba(255, 184, 28, 0.25);
        }
      }
      .react-select__indicator {
        padding-top: 2px;
        padding-bottom: 2px;
      }
    }
  }
  &.product-nature,
  &.attributes {
    width: 100%;
    .react-select__control {
      width: 100%;
      .text-white {
        color: #ffffff !important;
      }
      .react-select__placeholder {
        font-size: 11px;
      }
    }
  }
  &.attributes,
  &.category-filter {
    .react-select__control {
      .react-select__value-container:not(.react-select__value-container--is-focused) {
        max-height: 96px;
        overflow-y: auto;
        scrollbar-width: thin;
        &::-webkit-scrollbar {
          width: 5px;
        }
        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
          border-radius: 10px;
          margin: 8px 0;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
        }
      }
    }
  }
`
