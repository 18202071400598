import React, { Component } from 'react'

import { decorate } from 'mobx'
import { inject, observer } from 'mobx-react'

import { allowedToOrderRole } from 'helpers/Auth'
import { fields } from './fieldset'
import Checkbox from 'components/Common/Form/Checkbox'
import Form from 'components/Common/Form/index'
import FormContainer from 'components/Common/Form/Container'
import Input from 'components/Common/Form/Input'
import Modal from 'components/Common/Modal'
import Prompt, { DEFAULT_CONFIRM_MESSAGE } from 'components/Common/Prompt'
import ReactSelect from 'components/Common/Form/ReactSelect'
import ReadOnlyField from 'components/Common/Form/ReadOnlyField'
import ToasterNotify from 'helpers/ToasterNotify'

class ContactFormModal extends Component {
  form = null
  constructor(props) {
    super(props)
    if (props.form) {
      this.form = props.form
    } else {
      this.form = new Form(
        { fields: fields(props.data) },
        { name: 'ContactForm', hooks: { onSuccess: this.handleSubmit } }
      )
    }
  }

  componentWillMount() {
    this.props.commonRepository.getCommon('prefix', 'prefix', {})
  }
  componentWillUnmount() {
    this.props.customerContactRepository.clearErrorMessages()
  }
  handleSubmit = form => {
    let values = form.values()
    const { data, editMode, customer } = this.props
    const status = values.status ? 'ACTIVE' : 'INACTIVE'

    values.prefix = values.prefix ? values.prefix.value : null
    if (data && data.id && editMode) {
      this.props.customerContactRepository.patch(
        { ...values, id: data.id, status },
        () => {
          this.props.table.reload()
          this.props.toggleModal()
          this.props.onSubmit()
        },
        customer.id
      )
    } else {
      this.props.customerContactRepository.create(
        { ...values, status },
        () => {
          this.props.table.reload()
          this.props.toggleModal()
          this.props.onSubmit()
        },
        customer.id
      )
    }
  }
  triggerSubmit = e => {
    document.getElementById('cc-submit').click()
  }
  handleCloseModal = () => {
    const { toggleModal } = this.props
    if (this.form.isDirty) {
      ToasterNotify.confirm({
        message: <div style={{ whiteSpace: 'break-spaces' }}>{DEFAULT_CONFIRM_MESSAGE}</div>,
        title: 'Caution',
        onConfirm: () => toggleModal(),
      })
    } else {
      toggleModal()
    }
  }
  render() {
    const {
      isModalOpen,
      editMode,
      customerContactRepository: {
        CRUD: { errors, loading, loadingFailed },
      },
      commonRepository: {
        Common: { prefix },
      },
    } = this.props

    return (
      <Modal
        size="lg"
        open={isModalOpen}
        title={`${editMode ? `Edit ` : `Add New `} Contact`}
        confirmLabel={`Save`}
        confirmIcon={`save`}
        loading={loading}
        loadingMessage={`Saving please wait...`}
        onConfirm={e => this.form.submit()}
        onClose={this.handleCloseModal}
        hideConfirm={!allowedToOrderRole()}
      >
        <Prompt promptOnExit={this.form.isDirty} />
        <FormContainer
          title="Address Details"
          onSubmit={e => this.form.submit()}
          errors={loadingFailed && errors}
          plain
        >
          <div className="grouped">
            <ReactSelect
              field={this.form.$('prefix')}
              options={{
                options: prefix.data,
              }}
            />
            <Input field={this.form.$('suffix')} />
          </div>
          <div className="grouped">
            <Input field={this.form.$('firstName')} />
            <Input field={this.form.$('middleName')} />
            <Input field={this.form.$('lastName')} />
          </div>
          <div className="grouped">
            <Input field={this.form.$('email')} />
            <Input field={this.form.$('tel')} />
          </div>
          <Checkbox field={this.form.$('loginFlag')} className="ml-1 mt-4" />
          <Checkbox
            field={this.form.$('status')}
            label={this.form.$('status').value ? 'Active' : 'Inactive'}
            className="ml-1"
          />
          <ReadOnlyField
            value={this.form.$('referralCode').value}
            label={this.form.$('referralCode').label}
            className="col-6 px-0"
          />
          <button id="cc-submit" type="submit" className="invisible" />
        </FormContainer>
      </Modal>
    )
  }
}

export default decorate(inject('customerContactRepository', 'commonRepository')(observer(ContactFormModal)), {})
