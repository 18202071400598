import React, { Component } from 'react'

import { Button } from 'reactstrap'
import { decorate } from 'mobx'
import { inject, observer } from 'mobx-react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { allowedToAppAdminRole, Visible } from 'helpers/Auth'
import { PRODUCTS_PATH } from 'definitions'
import Card from 'components/Common/Card'
import IconButton from 'components/Common/Buttons/IconButton'
import ProductsModal from './ProductsModal'
import ReactSelect from 'components/Common/Form/ReactSelect'
import ReactTable from 'components/Common/ReactTable'
import ToasterNotify from 'helpers/ToasterNotify'

class SpecialProducts extends Component {
  state = {
    isModalOpen: false,
    isEditMode: false,
    isPromptDelete: false,
    selected: {},
    bulkAction: null,
  }
  toggleModal = (mode = false) => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
      isEditMode: mode,
    })
  }
  handleRowSelect = selectedRowId => {
    if (typeof selectedRowId !== 'undefined') {
      this.setSelected(selectedRowId)
    } else if (this.state.selectedGroup === selectedRowId) {
      return
    } else {
      this.setSelected(selectedRowId)
    }
  }
  handleDelete = item => {
    const { customer } = this.props

    ToasterNotify.confirm({
      message: `Are you sure you want to delete this contact?`,
      title: 'Warning',
      onConfirm: () =>
        this.props.customerSpecialProductRepository.delete({ customerId: customer.id, id: item.id }, () => {
          this.table.reload()
        }),
    })
  }

  handleSelected = ({ selection = {} }) => {
    this.setState({
      selected: Object.keys(selection),
    })
  }
  confirmDeletion = item => {
    ToasterNotify.confirm({
      message: 'Are you sure you want to delete this product?',
      title: 'Warning',
      onConfirm: this.handleDelete,
    })
    this.setState({
      itemToDelete: item,
    })
  }

  handleDelete = async () => {
    const { customer, customerSpecialProductRepository } = this.props
    await customerSpecialProductRepository.delete(
      { customerId: customer.id, products: [{ id: this.state.itemToDelete.id }] },
      () => {
        this.table.reload()
      }
    )
  }
  handleSubmitBulkAction = () => {
    const { bulkAction, selected } = this.state
    if (bulkAction === 'Remove') {
      const { customer, customerSpecialProductRepository } = this.props
      ToasterNotify.confirm({
        message: 'Are you sure you want to delete the selected product(s)?',
        title: 'Warning',
        onConfirm: async () => {
          await customerSpecialProductRepository.delete(
            { customerId: customer.id, products: selected.map(id => ({ id })) },
            () => {
              this.table.reload()
            }
          )
        },
      })
    }
  }
  render() {
    const { isModalOpen, isEditMode, selected, bulkAction } = this.state
    const { customer } = this.props
    const {
      Table: { loading, totalRecordCount, data, originalData },
      fetchTable,
    } = this.props.customerSpecialProductRepository
    return (
      <Card
        title="Special Products"
        titleActions={
          <Visible toAppAdminRole>
            <IconButton title="Add" onClick={() => this.toggleModal(false)} color="dark" outline />
          </Visible>
        }
      >
        <ReactTable
          multiSelect
          className="striped cell-vertical-center"
          columns={[
            {
              accessor: 'productCode',
              Header: 'Product Code',
              Cell: ({ original }) => (
                <Link to={`${PRODUCTS_PATH}/${original.id}`} className="ellipsis">
                  {original.productCode}
                </Link>
              ),
            },
            {
              accessor: 'name',
              Header: 'Name',
              Cell: ({ original }) => (
                <Link to={`${PRODUCTS_PATH}/${original.id}`} className="ellipsis">
                  {original.name}
                </Link>
              ),
            },
            {
              Header: 'Actions',
              headerClassName: 'justify-content-center',
              className: 'text-center p-1 justify-content-center',
              sortable: false,
              show: allowedToAppAdminRole(),
              Cell: ({ original }) =>
                allowedToAppAdminRole() && (
                  <IconButton icon="ios-trash" onClick={() => this.confirmDeletion(original)} />
                ),
            },
          ]}
          tableColumnExtensions={[{ columnName: 'name', width: 250 }]}
          data={data}
          loadData={params => fetchTable(params, customer.id)}
          loading={loading}
          totalRecordCount={totalRecordCount}
          totalPages={originalData.totalPages}
          ref={ref => (this.table = ref)}
          actions={
            <Visible toAppAdminRole>
              <div className="d-flex justify-content-end align-items-center mb-1">
                <ReactSelectStyled
                  className="mr-2"
                  options={{
                    placeholder: 'Bulk Action',
                    options: [{ value: 'Remove', label: 'Remove' }],
                    isDisabled: !selected.length,
                  }}
                  onChange={vals => {
                    this.setState({ bulkAction: vals.value })
                  }}
                />
                <Button
                  size="sm"
                  color="dark"
                  type="button"
                  disabled={!bulkAction}
                  onClick={this.handleSubmitBulkAction}
                >
                  Submit
                </Button>
              </div>
            </Visible>
          }
          selectionChanged={this.handleSelected}
        />
        {isModalOpen && (
          <ProductsModal
            isModalOpen={isModalOpen}
            toggleModal={() => this.toggleModal(true)}
            table={this.table}
            data={selected}
            editMode={isEditMode}
            customer={customer}
            setSelected={this.setSelected}
          />
        )}
      </Card>
    )
  }
}

const ReactSelectStyled = styled(ReactSelect)`
  .react-select {
    &__control {
      width: ${props => props.width || '180px'};
      border: none;
    }
  }
`

export default decorate(
  inject('customerContactRepository', 'customerSpecialProductRepository')(observer(SpecialProducts)),
  {}
)
