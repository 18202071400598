import React, { Component } from 'react'

import { decorate } from 'mobx'
import { inject, observer } from 'mobx-react'

import { allowedToAppAdminRole, Visible } from 'helpers/Auth'
import Card from 'components/Common/Card'
import IconButton from 'components/Common/Buttons/IconButton'
import ReactTable from 'components/Common/ReactTable'
import ToasterNotify from 'helpers/ToasterNotify'

import AddressFormModal from './AddressFormModal'

class Addresses extends Component {
  state = {
    isModalOpen: false,
    isEditMode: false,
    selected: {},
  }
  toggleModal = (isEditMode, selected) => {
    const isModalOpen = !this.state.isModalOpen
    this.setState({
      isModalOpen,
      isEditMode,
      selected,
    })
    this.props.onDisplayModeChanged(isModalOpen ? 'address' : '', selected?.id)
  }
  handleEdit = selected => {
    this.toggleModal(true, selected)
  }
  handleDelete = item => {
    const { customer } = this.props
    ToasterNotify.confirm({
      message: `Are you sure you want to delete this address?`,
      title: 'Warning',
      onConfirm: () =>
        this.props.customerAddressRepository.delete({ customerId: customer.id, id: item.id }, () => {
          this.table.reload()
        }),
    })
  }
  setSelected = selected => {
    this.setState({
      selected: selected,
    })
  }
  render() {
    const { isModalOpen, isEditMode, selected } = this.state
    const { customer } = this.props
    const {
      //   CRUD,
      Table: { data, loading, totalRecordCount, originalData },
      fetchTable,
    } = this.props.customerAddressRepository

    return (
      <Card
        title="Addresses"
        style={{ marginBottom: '15px' }}
        titleActions={
          <Visible toAppAdminRole>
            <div className="text-right">
              <IconButton title="Add" onClick={() => this.toggleModal(false)} color="dark" outline />
            </div>
          </Visible>
        }
      >
        <ReactTable
          className="striped cell-vertical-center"
          columns={[
            { accessor: 'company', Header: 'Company' },
            { accessor: 'country.label', Header: 'Country' },
            { accessor: 'city', Header: 'City' },
            { accessor: 'state', Header: 'State' },
            { accessor: 'zipCode', Header: 'ZIP Code' },
            {
              accessor: 'billing',
              Header: 'Default Billing',
              headerClassName: 'justify-content-center',
              className: 'justify-content-center',
              Cell: ({ original }) => (
                <div style={{ fontSize: '1.5em' }} className="d-flex align-items-center">
                  {original.billing && (
                    <ion-icon style={{ color: '#ffb81c', fontSize: '1em', paddingRight: 10 }} name="checkmark" />
                  )}
                  {original.billing && <span style={{ color: '#ffb81c' }}>★</span>}
                </div>
              ),
            },
            {
              accessor: 'shipping',
              Header: 'Default Shipping',
              headerClassName: 'justify-content-center',
              className: 'justify-content-center',
              Cell: ({ original }) => (
                <div style={{ fontSize: '1.5em' }} className="d-flex align-items-center">
                  {original.shipping && (
                    <ion-icon style={{ color: '#ffb81c', fontSize: '1em', paddingRight: 10 }} name="checkmark" />
                  )}
                  {original.shipping && <span style={{ color: '#ffb81c' }}>★</span>}
                </div>
              ),
            },
            {
              Header: 'Actions',
              headerClassName: 'justify-content-center',
              className: 'justify-content-center',
              show: allowedToAppAdminRole(),
              Cell: ({ original }) => (
                <React.Fragment>
                  <IconButton icon="ios-search" title="Edit" onClick={() => this.handleEdit(original)} />
                  {!original?.billing && (
                    <IconButton
                      icon="ios-trash"
                      title="Delete"
                      onClick={() => {
                        this.handleDelete(original)
                      }}
                    />
                  )}
                </React.Fragment>
              ),
            },
          ]}
          tableColumnExtensions={[{ columnName: 'name', width: 250 }]}
          data={data}
          loadData={params => fetchTable(params, customer.id)}
          loading={loading}
          totalRecordCount={totalRecordCount}
          totalPages={originalData.totalPages}
          ref={ref => (this.table = ref)}
          handleRowClick={({ original }) => (allowedToAppAdminRole() ? this.handleEdit(original) : null)}
        />
        {isModalOpen && (
          <AddressFormModal
            isModalOpen={isModalOpen}
            toggleModal={() => this.toggleModal(true)}
            table={this.table}
            data={selected}
            editMode={isEditMode}
            customer={customer}
            setSelected={this.setSelected}
            onSubmit={this.props.onSubmit}
          />
        )}
      </Card>
    )
  }
}

export default decorate(inject('customerAddressRepository')(observer(Addresses)), {})
