/* @flow */
import APIRepository from 'stores/APIRepository'
import BaseServices from './BaseServices'

export default class ProductServices extends BaseServices {
  api: APIRepository
  constructor(api: APIRepository) {
    const url = '/products'
    super(api, url)
    this.api = api
    this.url = url
  }
  export(key, payload, options = {}) {
    return this.api.post(`${this.url}/${key}?sort=name%2Casc`, payload, options)
  }
  downloadExportedFile(payload, options = {}) {
    return this.api.get('/attachments/download/generatedFile', payload, options)
  }
  async fetchListPrice({ storeId, ...payload }) {
    if (storeId) {
      return this.api.get(`${this.url}/${storeId}/listPrice`, payload)
    } else {
      return this.api.get(`${this.url}/listPrice`, payload)
    }
  }
  async getById(id) {
    return await this.api.get(`${this.url}/${id}`)
  }
  getProductAttributesPricingRules() {
    return this.api.get(`${this.url}/attributes/criteria`)
  }
  getStoreProducts(productId) {
    return this.api.get(`${this.url}/${productId}/storeProducts`)
  }
  getProductsByCategory(params, id) {
    return this.api.get(`${this.url}/category/${id}`, params)
  }
  getSelectAttribute(params, productId) {
    return this.api.get(`${this.url}/${productId}/attributes/values`, params)
  }
  getSmartAddCustomOptions(payload) {
    return this.api.get(`${this.url}/customOptions/smartAdd`, payload)
  }
  convertToVirtualNoSN(payload = {}) {
    const { id, ...rest } = payload
    return this.api.patch(`${this.url}/${id}/convertProductNature`, rest)
  }
  cloneProduct(productId, payload = {}) {
    return this.api.post(`${this.url}/clone/${productId}`, payload)
  }
}

export class ProductImageServices extends BaseServices {
  api: APIRepository
  constructor(api: APIRepository, productId) {
    const url = `/products`
    super(api, url)

    this.api = api
    this.url = url
  }

  async fetchTable(productId) {
    const response = await this.api.get(`${this.url}/${productId}/images`)
    return {
      data: {
        content: response.data,
      },
      errors: response.errors,
    }
  }

  async getById({ productId, id }) {
    return this.api.get(`${this.url}/${productId}/images/${id}`)
  }

  async delete({ productId, id }) {
    return this.api.del(`${this.url}/${productId}/images/${id}`)
  }

  create({ productId, file }) {
    return this.api.post(`${this.url}/${productId}/images`, file)
  }

  async setDefaultImage(productId, id) {
    return this.api.patch(`${this.url}/${productId}/defaultImage/${id}`)
  }
}

export class ProductOptionServices extends BaseServices {
  api: APIRepository
  constructor(api: APIRepository, productId) {
    const url = `/products/options`
    super(api, url)

    this.api = api
    this.url = url
  }

  async getById({ productId, id }) {
    return this.api.get(`${this.url}/${productId}/options/${id}`)
  }

  async delete({ productId, id }) {
    return this.api.del(`${this.url}/${productId}/options/${id}`)
  }

  create({ productId, ...payload }) {
    return this.api.post(`${this.url}/${productId}/options`, payload)
  }
}

export class ProductAttributeServices extends BaseServices {
  api: APIRepository
  constructor(api: APIRepository, productId) {
    const url = `/products/attributes`
    super(api, url)

    this.api = api
    this.url = url
  }

  getValues() {
    return this.api.get(`${this.url}/values`)
  }

  getProductByProductCode(payload) {
    return this.api.get(`${this.url}/values`, payload)
  }
}

export class ProductAttributeValuesServices extends BaseServices {
  api: APIRepository
  constructor(api: APIRepository) {
    const url = `/products/attributes/values`
    super(api, url)

    this.api = api
    this.url = url
  }
}

export class ProductCustomOptionServices extends BaseServices {
  api: APIRepository
  constructor(api: APIRepository, productId) {
    const url = `/products`
    super(api, url)

    this.api = api
    this.url = url
  }

  async fetchTable(productId, payload) {
    const response = await this.api.get(`${this.url}/${productId}/customOptions`, payload)
    return {
      data: {
        content: response.data,
      },
      errors: response.errors,
    }
  }

  async getById({ productId, id }) {
    return this.api.get(`${this.url}/${productId}/customOptions/${id}`)
  }

  async delete({ productId, id }) {
    return this.api.del(`${this.url}/${productId}/customOptions/${id}`)
  }

  patch({ productId, id, ...payload }) {
    return this.api.patch(`${this.url}/${productId}/customOptions/${id}`, payload)
  }

  create({ productId, ...payload }) {
    return this.api.post(`${this.url}/${productId}/customOptions`, payload)
  }
}
